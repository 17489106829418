import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from "@angular/core";
import { createMask } from "@ngneat/input-mask";
import { DatatableComponent, SelectionType } from "@swimlane/ngx-datatable";
import { ModalDirective } from "ngx-bootstrap/modal";
import { map, debounceTime, distinctUntilChanged, Subject, Subscription, takeUntil } from "rxjs";
import { DataTypeServices } from "src/services/data-type-services";
import { MethodServices } from "src/services/method-services";
import { selectItem_Array_Inter } from "../filter-dropdown-global/filter-dropdown-global";
import { HttpLoaderImageComponent } from "../http-loader-image/http-loader-image.component";
import { SpinnerVisibilityService } from "ng-http-loader";

export interface configTable {
    header?: {
        show?: {
            title?: boolean;
            line?: boolean;
        };
    };
    tipe?: "checkbox"; // khusus untuk table yang ada checkbox
    checkbox?: {
        showAllHeader?: boolean; // show Icon Checkbox All Header
    };
    modal?: {
        showCloseButton?: boolean; // tampilkan icon close modal
        modalDefault?: ModalDirective; // modal di kontrol dari element form-global (misal hide)
    };
    isDisabled?: {
        refresh?: boolean; // disabled refresh button
        export?: boolean; // disabled export button
        create?: boolean; // disabled create button
    };
    sortFirst?: {
        // sorting pertama kali sewaktu table di render (hanya kondisi 'bulk')
        column: any; // kolom apa saja yang akan di sort (key 'name' dari dataInput)
        tipe: "asc" | "desc"; // tipe sorting ascending atau descending
    };
}

// KONFIGURASI KOLOM TABEL
export interface dataInputType {
    idx?: number;
    name?: String;
    nameParamUrl?: String | null;
    label?: String;
    nameNgTemplate?: String | null; // name input element (e.g. <input type = "text" [name] = "nameNgTemplate")
    idNgTemplate?: String | null; // id input element (e.g. <input type = "text" [id] = "idNgTemplate")
    width?: number | undefined; // width kolom
    minWidth?: number | undefined; // min width kolom
    maxWidth?: number | undefined; // max width kolom
    headerClass?: any; // class untuk headerClass (e.g. text-left)
    cellClass?: any; // class untuk data item cell (e.g. text-left)
    sortable?: boolean; // icon sort ditampilkan atau tidak
    statusCellDataExists?: boolean;
    statusSummaryTemplate?: boolean; // tampilkan filter input (true / false)
    typeSummaryTemplate?: "text" | "dropdown" | "date" | "date-mon-year" | "time" | "currency" | "currency_no_decimal" | "number" | "number_decimal" | "number_decimal_dynamic"; // tipe filter kolom (e.g. text, number, number_decimal, currency, currency_no_decimal, date, time, dropdown)
    decimalSummaryTemp_Placeholder?: any;
    decimalSummaryTemp_Option?: any;
    dropdownCustomList?: any; // isi array dropdown (e.g. dropdownOffShift:selectItem_Array_Inter[] = [{"showText":"Yes","paramUrl":"true"},{"showText":"no","paramUrl":"false"}])
    statusFormProc?: boolean;
    noDataDisplay?: boolean; // menampilkan kata 'no data to display' di table untuk kondisi data kosong

    typeColumn?:
        | "default"
        | "nourut"
        | "badge_status_approved"
        | "badge_status_inprogress_completed"
        | "badge_status_inprogress_completed_error"
        | "badge_status_inprogress_completed_error_rollback"
        | "badge_status_inprogress_error"
        | "badge_per_column"
        | "innerHTML"
        | "badge_per_status_canidate"
        | "show_hierarchy"
        | "edit_show"
        | "view_log"
        | "download_logView"
        | "download_log"
        | "download_view_log"
        | "download_view_log"
        | "reprocess_rollback_log"
        | "reprocess_rollback_viewLog"
        | "status_success_or_failed"
        | "reprocess_rollback_log_costing"
        | "download_attachment"
        | "show_comment"
        | "edit_delete"
        | "edit"
        | "delete"
        | "history_show"
        | "flexible_action"
        | "flexible_action_disabled"
        | "increase_decrease"
        | "badge_status_new_inprogress_completed_passed_failed"
        | "show_success_status"
        | "edit_delete_request"
        | "edit_delete_point"
        | "reprocess"
        | "badge_status_new_inprogress_completed_error"
        | "currency_label"; // flexible_action khusus untuk kondisi action enabled / disabled yang berbeda per-row nya, dan juga bisa looping otomatis (edit, delete, dst...)
    flexible_actions?: flexible_action_arr[];

    typeSortColumn?: "number" | "text" | "date"; // 'number' or 'text' or 'date'
    nameSortColumn?: any; // nama kolom yang akan di sort yang berbeda dari nameParamUrl (misal: kolom currency (moneyLabel), secara label "100.000" namun secara sorting pakai kolom number "100000" (money))
    readOnly?: boolean; // disabled action semua row (e.g. edit, delete, dst...)
    cellIsEmpty?: boolean; // untuk validasi apakah data boleh di input kosong atau tidak
    excelExport?: boolean; // apa kolom tersebut akan di export ke excel
}

[];

interface flexible_action_arr {
    tipe_action: "edit" | "delete" | "download"; // edit,delete,dst...
    title?: any;
    disabled_key?: any; // nama kolom key yang akan di periksa
    disabled_value?: any; // value kolom key yang jadi indikator apakah akan disabled
}

interface _subject_rows_global {
    tableId: any; // mandatory isi "tableId" sebagai pembeda antara satu dengan table dengan yang lainnya
    data: [] | {};
    modalDefault?: ModalDirective;
    arrCheckDuplicate?: any[]; // cek duplicate (nama key yang mau dicek silahkan dimasukkin) (untuk add / edit)
    arrCheckDuplicate_Msg?: any; // message yang ditampilkan pada saat terdapat data yang tidak valid karena duplicate (untuk add / edit)
    deleteKeyNull?: any[]; // menghapus data dengan key yang bernilai null (kondisi saat ini hanya berlaku untuk tipe == 'edit')
}

interface subject_rows_add extends _subject_rows_global {
    tipe: "bulk" | "bulk_insert" | "add"; // 'bulk'->masukkan sekaligus data array / object; 'bulk_insert'->menambah data baru tanpa replace data sebelumnya dan hanya menambah yang kurang saja, 'add'->tambah data baru; 'edit'->edit data dan harus ada rowIndex
    tableId_ProgressBar?: any;
}

interface subject_rows_modify extends _subject_rows_global {
    tipe: "edit" | "delete";
    rowIndex: any;
}

export type subject_rows_Type = subject_rows_add | subject_rows_modify;

// UNTUK MEMBEDAKAN KONDISI ADD, EDIT, DELETE, SORT DATA DALAM SATU FUNCTION
export interface element_global_type {
    tableId: any;
    tipe: "add" | "edit" | "delete" | "add_complete" | "edit_complete" | "delete_complete" | "form_proc" | "sort" | "filter" | "close_modal" | "error" | "refresh" | "select_list" | "bulk_insert_complete" | "bulk_complete" | "after_view_init" | "search_input" | "change_page" | "change_entries";
    rowIndex?: number;
    rows?: any[];
    temp?: any[];
    selected_array?: any[];
    selectedRow?: any;
    paramsFilter?: any;
    page?: any;
}

// export let subject_rows:BehaviorSubject<subject_rows_Type | [...any]> = new BehaviorSubject<subject_rows_Type | [...any]>([]);   // memory leak minimal 1x
export const subject_rows: Subject<subject_rows_Type | any[]> = new Subject<subject_rows_Type | any[]>();
export const subject_ProgressBar: Subject<any> = new Subject<any>();

@Component({
    selector: "ngx-datatable-form-global",
    templateUrl: "ngx-datatable-form-global.html",
    styleUrls: ["ngx-datatable-form-global.scss"],
})
export class NgxDatatableFormGlobal implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    progressValue: any = 0;

    public httpLoaderImage = HttpLoaderImageComponent;

    SelectionType: SelectionType;

    @Input() selectedListCheckbox: any[] = [];

    showTable: boolean = true;

    dateInputMask = createMask<Date>({
        alias: "datetime",
        inputFormat: "dd-mm-yyyy",
        parser: (value: string) => {
            const values = value.split("-");
            const year = +values[2];
            const month = +values[1] - 1;
            const date = +values[0];
            return new Date(year, month, date);
        },
    });

    timeInputMask = createMask<Date>({
        alias: "datetime",
        inputFormat: "HH:MM",
        placeholder: "hh:mm",
        showMaskOnFocus: true,
        showMaskOnHover: false,
    });

    dateMonYearInputMask = createMask<Date>({
        alias: "datetime",
        inputFormat: "mm-yyyy",
        parser: (value: string) => {
            const values = value.split("-");
            const year = +values[1];
            const month = +values[0] - 1;
            return new Date(year, month, 1);
        },
    });

    currencyInputMask = createMask({
        alias: "numeric",
        groupSeparator: ",",
        digits: 2,
        digitsOptional: false,
        prefix: "",
        placeholder: "0.00",
        allowMinus: false,
        onKeyDown: (event) => {},
    });

    currencyNoDecInputMask = createMask({
        alias: "numeric",
        groupSeparator: ",",
        digits: 0,
        digitsOptional: false,
        prefix: "",
        placeholder: "_",
        allowMinus: false,
        onKeyDown: (event) => {},
    });

    numberInputMask = createMask({
        alias: "numeric",
        groupSeparator: "",
        digits: 0,
        digitsOptional: false,
        prefix: "",
        placeholder: "0",
        allowMinus: false,
    });
    numberInputMask_Temp = createMask({
        alias: "numeric",
        groupSeparator: "",
        digits: 0,
        digitsOptional: false,
        prefix: "",
        placeholder: "_",
        allowMinus: false,
        rightAlign: true,
        onKeyDown(event) {
            if (event.key.toLowerCase() == "m") {
                return false;
            }
        },
    });

    numberInputMaskDecimal_Temp = createMask({
        alias: "numeric",
        groupSeparator: "",
        digits: 2,
        digitsOptional: true,
        prefix: "",
        placeholder: "_.__",
        showMaskOnHover: true,
        allowMinus: false,
        rightAlign: true,
        autoUnmask: false, // otomatis validasi di parameter _ jadi 0 jika true
        onKeyDown(event) {
            if (event.key.toLowerCase() == "m") {
                return false;
            }
        },
    });

    positionCurrentId: any;
    positionCurrentId_Prev: any;
    positionCurrentId_Value: any;

    aktif_table: boolean = true;

    filterNoUrut = { typeColumn: "nourut" };
    filterDefault = { typeColumn: "default" };

    // ROWS UNTUK DIKIRIM KE COMPONENT DEPAN (TERUTAMA KONDISI 'DELETE')
    rows_send: any[] = [];
    // ROWS awal dari backend untuk dikirim ke button export excel
    rows_excel: any[] = [];
    dataInput_excel: dataInputType[] = [];
    @Input() needHitAPI?: boolean = false;
    @Input() modelEffectiveDate: any;
    @Input() modelEffectiveDateFinal: any;
    @Input() temp: any[] = [];
    @Input() rows: any[] = [];
    @Input() selectedActive: any;
    @Input() hideSortFilterTable: any[] = []; //array sort column will hide e.g. [0,1,2]
    @Input() dataExists: boolean;
    @Input() statusFinishedApi: boolean;
    @Input() statusAODParamUrl?: boolean; // apa ada AOD yg perlu di include ke param URL hit API

    @Input() dataInput: dataInputType[] = [];

    @Input() tableId: any;
    @Input() tableIcon: any;
    @Input() tableName: any;
    @Input() showCreateButton: boolean;
    @Input() showExportButton: boolean;
    @Input() showViewTemplate: boolean = false;
    @Input() showHeader: boolean = true;

    @Input() showRefreshButton: boolean;
    @Input() statusDisabledDropdown: boolean = false; // disabled (true or false) status active or any status in dropdown toggle
    @Input() avatarDivWidth: any = "45px"; // width logo avatar above (sebelah nama form)
    @Input() avatarFontSize: any = "30px"; // font size logo avatar

    // EXPORT BUTTON OPTIONS
    @Input() dataParamsExport: any;
    @Input() exportApiGetData: any = "/api/v1/role/export";
    @Input() exportProcessName: any = "RPT_SS_SECURITY_USER";
    @Input() exportNeedAOD: any = true;
    @Input() exportIsDisabled: any = false;

    @Input() totalElements: number = 0;

    @Output() elementGlobal = new EventEmitter<element_global_type>();
    @Output() createElementGlobal: any = new EventEmitter<any>();
    @Output() selectProcGlobal: any = new EventEmitter<any>();
    @Output() formProcGlobal: any = new EventEmitter<any>();
    @Output() elementClassLoadGlobal: any = new EventEmitter<any>();
    @Output() keypressGlobal: any = new EventEmitter<any>();
    @Output() showHierarchyGlobal: any = new EventEmitter<any>();
    @Output() editShowGlobal: any = new EventEmitter<any>();
    @Output() rangePickerChangeGlobal: any = new EventEmitter<any>();
    @Output() reprocessFuncGlobal: any = new EventEmitter<any>();
    @Output() rollbackFuncGlobal: any = new EventEmitter<any>();
    @Output() downloadLogViewGlobal: any = new EventEmitter<any>();
    @Output() viewTemplateGlobal: any = new EventEmitter<any>();

    // untuk dropdown dengan list custom
    @Input() dropdownCustomList: selectItem_Array_Inter[] = [];

    // untuk custom status (all, active, inactive, in progress, completed, dst ... )
    @Input() showStatusDropdown: boolean;
    @Input() headerStatusCode: any = 0; // 0 -> (all, active, inactive)
    @Input() headerStatusKey: any = "active"; // key (active) yang dikirim ke backend (e.g. ?active=true)
    headerStatusList: any[] = []; // JSON dari statusCode yang dimasukkan
    // ... end

    // untuk date range picker
    rangePicker_show: boolean = false;
    rangePicker_blockRender: boolean = true;

    // range value diisi untuk kondisi awal
    bsRangeValue_Start: any;
    bsRangeValue_End: any;

    bsRangeValue: any = [];
    @Input() headerStatusRangePicker: boolean = false; // di tampilkan atau tidak
    @Input() headerRangePickerMaxDate: any = 59; // default 60 days  (tidak mandatory di isi)
    @Input() headerRangePickerKeyStartDate: any = "startDate"; // param key untuk kirim api (e.g. startDate)
    @Input() headerRangePickerKeyEndDate: any = "endDate"; // param key untuk kirim api (e.g. endDate)
    @Input() headerRangePickerValueEndDate: any = new Date();
    // ... end

    // DELETE ACTION DARI TABLE (ID DARI BACKEND)
    @Input() cellKeyId: any; // keyId dari backend untuk "delete" (jika ada id, maka action="DELETE". jika tidak ada id, maka langsung dihapus)
    // ... end DELETE ACTION

    @Input() configTable: configTable;

    activeRow: any;
    entries: number = 10;
    selected: any[] = [];
    arrFilterHeader: any[] = [];
    gabung: any;
    dataInputSort: any[] = [];
    widthColumn: any;

    numberIndexing: number = 1;
    currentPage: number = 1;

    @ViewChild("nativeFilter1") nativeFilter1: ElementRef;
    @ViewChild("nativeFilter2") nativeFilter2: ElementRef;

    @ViewChild("dataTable") dataTable: DatatableComponent;

    statusDark: boolean = false;
    tempLength: any;
    totalRecord_String: any = "0";

    subscription: Subscription;
    ngUnsubscribe: Subject<any> = new Subject();

    public keyUp = new Subject<any>();

    // progress bar
    progress_show: boolean = false;
    progress_max: any = 0;

    status_Sort_Completed: boolean = false;

    constructor(public methodServices: MethodServices, public spinner: SpinnerVisibilityService, public dataTypeServices: DataTypeServices) {
        this.methodServices.filterTempSubject_Merge.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            setTimeout(() => {
                this.temp = [];
                this.temp = [...this.methodServices.filterTempObject_Merge[this.tableId]];
            }, 150);
        });

        // KEYUP SUBSCRIPTION utk text atau number
        this.subscription = this.keyUp
            .pipe(
                map((event: any) => event),
                debounceTime(100),
                distinctUntilChanged()
            )
            .subscribe((res) => {
                if (typeof this.dataTable != "undefined") {
                    this.dataTable.sorts = [];
                }
            });

        // --- (subject_rows) HIT / FILL DATA---
        // jika dilempar bulk array rows atau temp, array harus iniasiasi dengan object key 'data' (e.g. {data: [{id:1, nama:'example'}]} )
        // jika dilempar array / object (dari edit, delete, create), maka harus ada initiasiasi object key
        // -> rowIndex hanya khusus edit / delete data
        // -> (e.g. { data:{'examplekey':'valuekey'}, tipe:'edit', rowIndex:0 })
        // -> (e.g. { data:[{'examplekey':'valuekey'}], tipe:'bulk' })
        // -> (e.g. [])

        // KHUSUS UNTUK PROGRESS BAR (e.g. 'bulk_insert')
        subject_ProgressBar.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            if (result?.rows_get?.["tableId_ProgressBar"] == this.tableId) {
                if (result?.rows_get?.["tipe"] == "bulk_insert") {
                    if (typeof result?.["progress_max"] != "undefined") {
                        this.progress_max = result?.["progress_max"];
                    }

                    if (typeof result?.["progress"] != "undefined") {
                        this.SelectionType = SelectionType.checkbox;
                        this.progress_show = true;
                        this.progressValue = result?.["progress"];
                    } else {
                        this.SelectionType = SelectionType.multiClick;
                        this.progress_show = false;
                        this.progressValue = 0;
                    }
                }
            }
        });
        // ... end

        subject_rows.pipe(takeUntil(this.ngUnsubscribe)).subscribe((rows_get: subject_rows_Type) => {
            if (typeof this.tableId != "undefined") {
                subject_ProgressBar.next({ rows_get, progress: 0 });

                // jadikan data null ke kosong (blank) supaya bisa ter-sort

                let rows_get_final: any = this.generate_non_null_final(rows_get);

                // ... <end>

                this.fillData(rows_get_final);
            } else {
                // JIKA tableId dari form masih undefined, maka setInterval untuk menunggu tableId
                let setInt = setInterval(() => {
                    if (typeof this.tableId != "undefined") {
                        subject_ProgressBar.next({ rows_get, progress: 0 });

                        // jadikan data null ke kosong (blank) supaya bisa ter-sort
                        // let obj_non_null:any;

                        let rows_get_final: any = this.generate_non_null_final(rows_get);
                        // ... <end>

                        this.fillData(rows_get_final);

                        clearInterval(setInt);
                    }
                });
            }
        });
    }

    generate_non_null_final(rows_get: any) {
        // jadikan data null ke kosong (blank) supaya bisa ter-sort
        let obj_non_null_data: any;
        let obj_final: any;

        if (typeof rows_get == "object" && rows_get != null) {
            obj_final = rows_get;

            if (typeof rows_get?.["data"] != "undefined" && rows_get?.["data"] != null) {
                obj_non_null_data = this.generate_non_null_obj(JSON.parse(JSON.stringify(rows_get?.["data"])));
                obj_final["data"] = obj_non_null_data;
            }
        }

        return obj_final;
        // ... <end>
    }

    generate_non_null_obj(obj: any) {
        Object.entries(obj).forEach(([k, v]) => {
            // if (v instanceof Object){
            if (typeof v == "object" && v != null) {
                this.generate_non_null_obj(v);
            }
            if (v == null) {
                obj[k] = "";
            }
        });

        return obj;
    }

    keyUp_Proc($event: any, column: any, typeTemplate: any) {
        this.keyUp.next({ evt: $event, column: column, template: typeTemplate });
    }

    generate_date_sort(obj: any) {
        // obj -> (e.g. {id:1, example_key:'example_value'} )
        // hasil return -> (e.g. {date_Sort:20223001})

        // GENERATE key baru dengan suffix "_Sort" khusus typeSummaryTemplate == "date"
        let temp_dataInput: any[] = [];
        let temp_date_sort: any;

        temp_date_sort = {};

        if (typeof this.dataInput != "undefined" && this.dataInput.length > 0) {
            temp_dataInput = this.dataInput.filter((dt) => dt["typeSummaryTemplate"] == "date");
            if (temp_dataInput.length > 0) {
                // LOOPING dataInput
                temp_dataInput.forEach((tmp_dt) => {
                    let name_sort: any = tmp_dt["name"].toString() + "_Sort";

                    if (typeof obj?.[tmp_dt?.["name"]] != "undefined" && obj?.[tmp_dt?.["name"]] != null) {
                        if (!isNaN(obj?.[tmp_dt?.["name"]])) {
                            let date_format: any;

                            try {
                                date_format = parseInt(this.methodServices.formatDate(new Date(obj[tmp_dt["name"]]), "YYYYMMDD"));
                                temp_date_sort[name_sort] = date_format;
                            } catch (e) {
                                temp_date_sort[name_sort] = 0; // (e.g. 0) only if error
                            }
                        } else if (typeof obj?.[tmp_dt?.["name"]] == "string") {
                            try {
                                let pattern: any = /[0-9]{2}-([a-zA-Z]+)-[0-9]{2}/g; // (e.g. 30-Apr-2022)
                                if (pattern.test(obj?.[tmp_dt?.["name"]])) {
                                    let date_parseInt: any = parseInt(this.methodServices.formatDate(new Date(obj?.[tmp_dt?.["name"]]), "YYYYMMDD"));
                                    temp_date_sort[name_sort] = date_parseInt; // (e.g. 20220130)
                                } else {
                                    let pattern: any = /[0-9]{4}-[0-9]{2}-[0-9]{2}/g; // (e.g. 2022-04-30)
                                    if (pattern.test(obj?.[tmp_dt?.["name"]])) {
                                        let date_parseInt: any = parseInt(this.methodServices.formatDate(new Date(obj?.[tmp_dt?.["name"]]), "YYYYMMDD"));
                                        temp_date_sort[name_sort] = date_parseInt; // (e.g. 20220130)
                                    }
                                }
                            } catch (e) {
                                temp_date_sort[name_sort] = 0; // (e.g. 0) only if error
                            }
                        }
                    }

                    let find_item = this.dataInputSort.find((dtInput) => dtInput == name_sort);
                    if (!find_item) {
                        this.dataInputSort.push(name_sort); // date_Sort, holidayDate_Sort
                    }
                });
                // ... end LOOPING dataInput

                return { ...temp_date_sort };
            } else {
                return {};
            }
        }
    }

    fillData(rows_get: subject_rows_Type | any) {
        // (1) DATA ARRAY tanpa key 'data' (e.g. [])
        if (Array.isArray(rows_get) || typeof rows_get?.["data"] == "undefined" || rows_get?.["data"] == null) {
            if (typeof rows_get?.["data"] == "undefined") {
                this.dataExists = false;
                this.fill_Null_Object();
                this.methodServices.filterTempObject_Merge[this.tableId] = [...this.temp];
                this.totalRecord_String = this.methodServices.formatNumber(0, "decimal");

                this.elementGlobal.emit({ tableId: this.tableId, tipe: "error", rows: [], temp: [] });
                return;
            }
        }
        // ... end (1)

        let status_finished: boolean = false;
        let selected_array_total: any = 0;

        this.progressValue = 0; // dipakai pada 'bulk_insert'

        if (typeof rows_get?.["data"] != "undefined") {
            selected_array_total = rows_get?.["data"].length;
        }

        if (rows_get?.["tableId_ProgressBar"] == this.tableId) {
            let setInt = setInterval(() => {
                if (this.progressValue >= selected_array_total || selected_array_total == 0) {
                    this.progressValue = undefined;
                    subject_ProgressBar.next({ rows_get, progress: this.progressValue });

                    clearInterval(setInt);
                }
            });
        }

        // HANYA "TABLE ID" YANG SESUAI, BARU AKAN DI PROSES. KARENA BISA JADI ADA 2 TABLE DENGAN TABLE ID YANG BERBEDA.
        if (rows_get["tableId"] == this.tableId) {
            // DATA ARRAY dengan ada key 'data'
            if (Array.isArray(rows_get["data"])) {
                if (rows_get["data"].length == 0 || rows_get["data"] == null || typeof rows_get["data"] == undefined) {
                    // CEK APAKAH DATA KOSONG, JIKA KOSONG MAKA DI PUSH DENGAN SATU DATA 'NULL' SUPAYA INPUT FILTER MUNCUL
                    let setInt = setInterval(() => {
                        if (this.dataInput.length > 0) {
                            this.dataExists = false;

                            // EXPORT KE EXCEL
                            // FILTER DATA INPUT EXCEL dengan catatan tidak ada key excelExport nya atau excelExport nya bernilai "true"
                            this.dataInput_excel = this.dataInput.filter((arr) => {
                                return typeof arr?.["excelExport"] == "undefined" || arr?.["excelExport"] == null || (typeof arr?.["excelExport"] != "undefined" && arr?.["excelExport"]);
                            });

                            // PUSH SATU DATA NULL
                            this.fill_Null_Object(); //generate rows & temp to 'NULL'

                            this.methodServices.filterTempObject_Merge[this.tableId] = [...this.temp];

                            this.totalRecord_String = this.methodServices.formatNumber(0, "decimal");

                            this.elementGlobal.emit({ tableId: this.tableId, tipe: "error", rows: [], temp: [] });

                            if (typeof this.tableId != "undefined" && this.tableId != null && this.tableId != "") {
                                this.hideSortTable();
                            }

                            this.fixingTableInvisible();

                            clearInterval(setInt);
                        }
                    });
                } else {
                    // TIPE ARRAY & BULK (langsung di timpa ke rows & temp)
                    if (rows_get["tipe"] == "bulk") {
                        let setInt = setInterval(() => {
                            if (this.dataInput.length > 0) {
                                this.rows = [];
                                this.temp = [];

                                this.dataExists = true;

                                // ROWS INDEX AKAN DI CREATE OTOMATIS (index / indexRows)

                                // COPY rows_get['data'] terlebih dahulu ke var rows & temp, sebelum di sorting manual
                                this.rows = JSON.parse(JSON.stringify(rows_get["data"]));
                                this.temp = JSON.parse(JSON.stringify(rows_get["data"]));

                                // set ke false dulu untuk sort manual
                                this.status_Sort_Completed = false;

                                // SORTING MANUAL JIKA ADA CONFIG-nya
                                this.sortFirst_Load();
                                // ... end sorting

                                // TUNGGU JIKA SORT MANUAL SUDAH SELESAI, MAKA DI FILTER INPUT ELEMENT
                                let setInt_sortCompleted = setInterval(() => {
                                    if (this.status_Sort_Completed) {
                                        this.rows = this.rows.map((result_Data, index, arr) => {
                                            // TAMBAHAN OBJECT DATE Sort dengan format YYYYMMDD yang di input secara otomatis ke dateInputSort
                                            let obj_date_sort = this.generate_date_sort({ ...result_Data }); // generate date sort dengan format YYYYMMDD
                                            if (Object.entries(obj_date_sort).length > 0) {
                                                return {
                                                    ...obj_date_sort, // format date sort (e.g. holidayDate -> holidayDate_Sort)
                                                    index: index, // otomatis index
                                                    ...result_Data, // original data
                                                    indexRows: index, // otomatis index
                                                };
                                            } else {
                                                return {
                                                    index: index, // otomatis index
                                                    ...result_Data, // original data
                                                    indexRows: index, // otomatis index
                                                };
                                            }
                                        });

                                        // EXPORT KE EXCEL
                                        // FILTER DATA INPUT EXCEL dengan catatan tidak ada key excelExport nya atau excelExport nya bernilai "true"
                                        this.dataInput_excel = this.dataInput.filter((arr) => {
                                            return typeof arr?.["excelExport"] == "undefined" || arr?.["excelExport"] == null || (typeof arr?.["excelExport"] != "undefined" && arr?.["excelExport"]);
                                        });

                                        if (this.rows.length > 0) {
                                            this.rows_excel = JSON.parse(JSON.stringify([...this.rows]));

                                            this.rows_excel = this.rows_excel.map((obj, idx, arr) => {
                                                // ambil kolom yang excelExport nya bernilai "false" (yang tidak di izinkan utk di export)
                                                let dtInput_filter: any = this.dataInput.filter((dtinput) => {
                                                    return typeof dtinput?.["excelExport"] != "undefined" && !dtinput?.["excelExport"];
                                                });

                                                dtInput_filter.forEach((dtInput: any) => {
                                                    delete obj[dtInput["name"]];
                                                });

                                                return obj;
                                            });
                                        }

                                        // ... end

                                        this.methodServices.filterTempObject_Merge[this.tableId] = [...this.temp];

                                        // FIXING WIDTH DATATABLE KARENA TIDAK TAMPIL SAAT KLIK HISTORY ITEM
                                        this.fixingTableInvisible();

                                        this.totalRecord_String = this.needHitAPI ? this.methodServices.formatNumber(this.totalElements, "decimal") : this.methodServices.formatNumber(this.temp.length, "decimal");

                                        // FILTER KEMBALI DATA YANG DI-DAPATKAN
                                        this.addAodNSelectedActive();

                                        this.filter_Input_Form(this.arrFilterHeader);
                                        this.status_Sort_Completed = false;

                                        this.elementGlobal.emit({
                                            tableId: this.tableId,
                                            tipe: "bulk_complete",
                                            rows: [...this.rows],
                                            temp: [...this.temp],
                                        });

                                        clearInterval(setInt_sortCompleted);
                                    }
                                });

                                this.hideSortTable(500);

                                clearInterval(setInt);
                            }
                        });
                    }

                    // BULK INSERT (data ditambahkan yang tidak ada di "rows")
                    if (rows_get["tipe"] == "bulk_insert") {
                        // hapus rows dengan indexRows == null terlebih dahulu (asumsi satu record saja ada kemungkinan data null)
                        if (this.rows.length == 1) {
                            let find_null = this.rows.find((row) => {
                                return typeof row?.["indexRows"] == "undefined" || row?.["indexRows"] == null;
                            });

                            if (find_null) {
                                let rowTemp: any[] = [];
                                rowTemp = this.rows.filter((row) => {
                                    return typeof row?.["indexRows"] != "undefined" && row?.["indexRows"] != null;
                                });
                                this.rows = [...rowTemp];
                            }
                        }
                        // ... end hapus rows

                        // ROWS INDEX AKAN DI CREATE OTOMATIS (index / indexRows)

                        if (typeof rows_get?.["data"] != "undefined" && rows_get?.["data"] != null) {
                            if ((rows_get?.["data"]).length > 0) {
                                // SELECTED ARRAY
                                for (let selected_obj of rows_get?.["data"]) {
                                    setTimeout(() => {
                                        // PERIKSA APA ADA DATA YANG DUPLICATE
                                        // arrCheckDuplicate
                                        if (typeof rows_get?.["arrCheckDuplicate"] != "undefined" && rows_get?.["arrCheckDuplicate"] != null) {
                                            // arrCheckDuplicate > 0
                                            if (rows_get?.["arrCheckDuplicate"].length > 0) {
                                                let validFilter: any = "";

                                                // ARRAY ROW DETAIL
                                                if (this.rows.length > 0) {
                                                    // FIND ITEM IN ROWS
                                                    let find_item = this.rows.find((row) => {
                                                        // LOOPING KOLOM TER-SELECT
                                                        validFilter = "";

                                                        for (let arrCheckDuplicate of rows_get?.["arrCheckDuplicate"]) {
                                                            validFilter += " && '" + row?.[arrCheckDuplicate] + "'=='" + selected_obj?.[arrCheckDuplicate] + "'";
                                                        }

                                                        if (validFilter != "") {
                                                            validFilter += " && " + "(" + "('" + typeof row?.["action"] + "' != 'undefined' && '" + row?.["action"] + "' != 'DELETE'" + ")" + " || '" + typeof row?.["action"] + "' == 'undefined')";

                                                            validFilter = validFilter.substr(0, 4) == " && " ? validFilter.slice(4) : validFilter;

                                                            if (eval(validFilter)) {
                                                                return true;
                                                            }
                                                        }
                                                    });
                                                    // ... end FIND ITEM IN ROWS

                                                    if (!find_item) {
                                                        // jika tidak ketemu, maka dipush
                                                        this.dataExists = true;
                                                        selected_obj["index"] = this.rows.length;
                                                        selected_obj["indexRows"] = this.rows.length;

                                                        // TAMBAHAN OBJECT DATE Sort dengan format YYYYMMDD yang di input secara otomatis ke dateInputSort
                                                        let obj_date_sort = this.generate_date_sort({ ...selected_obj }); // generate date sort dengan format YYYYMMDD
                                                        if (Object.entries(obj_date_sort).length > 0) {
                                                            selected_obj = {
                                                                ...obj_date_sort, // format date sort (e.g. holidayDate -> holidayDate_Sort)
                                                                ...selected_obj, // original data
                                                            };
                                                        }
                                                        // ... end TAMBAHAN OBJECT DATE SORT

                                                        this.rows.push({ ...selected_obj });
                                                    }
                                                } else {
                                                    // KONDISI ROWS LENGTH == 0
                                                    this.dataExists = true;
                                                    selected_obj["index"] = this.rows.length;
                                                    selected_obj["indexRows"] = this.rows.length;

                                                    // TAMBAHAN OBJECT DATE Sort dengan format YYYYMMDD yang di input secara otomatis ke dateInputSort
                                                    let obj_date_sort = this.generate_date_sort({ ...selected_obj }); // generate date sort dengan format YYYYMMDD
                                                    if (Object.entries(obj_date_sort).length > 0) {
                                                        selected_obj = {
                                                            ...obj_date_sort, // format date sort (e.g. holidayDate -> holidayDate_Sort)
                                                            ...selected_obj, // original data
                                                        };
                                                    }
                                                    // ... end TAMBAHAN OBJECT DATE SORT

                                                    this.rows.push({ ...selected_obj });
                                                }
                                            }
                                            // ... end arrCheckDuplicate > 0
                                            else {
                                                this.dataExists = true;
                                                selected_obj["index"] = this.rows.length;
                                                selected_obj["indexRows"] = this.rows.length;

                                                // TAMBAHAN OBJECT DATE Sort dengan format YYYYMMDD yang di input secara otomatis ke dateInputSort
                                                let obj_date_sort = this.generate_date_sort({ ...selected_obj }); // generate date sort dengan format YYYYMMDD
                                                if (Object.entries(obj_date_sort).length > 0) {
                                                    selected_obj = {
                                                        ...obj_date_sort, // format date sort (e.g. holidayDate -> holidayDate_Sort)
                                                        ...selected_obj, // original data
                                                    };
                                                }
                                                // ... end TAMBAHAN OBJECT DATE SORT

                                                this.rows.push({ ...selected_obj });
                                            }
                                        } else {
                                            this.dataExists = true;
                                            selected_obj["index"] = this.rows.length;
                                            selected_obj["indexRows"] = this.rows.length;

                                            // TAMBAHAN OBJECT DATE Sort dengan format YYYYMMDD yang di input secara otomatis ke dateInputSort
                                            let obj_date_sort = this.generate_date_sort({ ...selected_obj }); // generate date sort dengan format YYYYMMDD
                                            if (Object.entries(obj_date_sort).length > 0) {
                                                selected_obj = {
                                                    ...obj_date_sort, // format date sort (e.g. holidayDate -> holidayDate_Sort)
                                                    ...selected_obj, // original data
                                                };
                                            }
                                            // ... end TAMBAHAN OBJECT DATE SORT

                                            this.rows.push({ ...selected_obj });
                                        }
                                        // ... end arrCheckDuplicate

                                        this.progressValue += 1;
                                        subject_ProgressBar.next({
                                            rows_get,
                                            progress: this.progressValue,
                                            progress_max: rows_get?.["data"].length,
                                        });
                                    }, 30);
                                }
                            }
                        }

                        // ... end

                        let setInt = setInterval(() => {
                            if (this.progressValue >= selected_array_total) {
                                this.temp = JSON.parse(JSON.stringify([...this.rows])); // deep clone (independent)

                                // FIXING WIDTH DATATABLE KARENA TIDAK TAMPIL SAAT KLIK HISTORY ITEM
                                this.fixingTableInvisible();

                                this.totalRecord_String = this.needHitAPI ? this.methodServices.formatNumber(this.totalElements, "decimal") : this.methodServices.formatNumber(this.temp.length, "decimal");

                                // FILTER KEMBALI DATA YANG DI-DAPATKAN
                                this.addAodNSelectedActive(); // simpan filter aod dan header status ke dalam arrFilterHeader
                                this.filter_Input_Form(this.arrFilterHeader);

                                if (typeof rows_get?.["modalDefault"] != "undefined" && rows_get?.["modalDefault"] != null) {
                                    rows_get?.["modalDefault"].hide();
                                }

                                this.elementGlobal.emit({
                                    tableId: this.tableId,
                                    tipe: "bulk_insert_complete",
                                    rows: [...this.rows],
                                    temp: [...this.temp],
                                });
                                clearInterval(setInt);
                            }
                        });
                    }
                }
            }

            // DATA OBJECT => {}
            else if (rows_get["data"] && rows_get["data"] instanceof Object) {
                let tempData = {};

                // TAMBAH DATA BARU
                if (rows_get["tipe"] == "add") {
                    if (this.rows.length > 0) {
                        // CEK APA ADA array "ROWS" INDEXROWS YANG NULL / UNDEFINED, jika ada maka secara logika harusnya data kosong dan hanya tinggal satu data null saja
                        let find_null_Index_Rows = this.rows.find((row) => row?.["indexRows"] == null || typeof row?.["indexRows"] == "undefined");
                        if (find_null_Index_Rows) {
                            this.rows = this.rows.filter((row) => {
                                return row?.["indexRows"] != null && typeof row?.["indexRows"] != "undefined";
                            });
                            this.temp = [...this.rows];

                            if (this.temp.length == 0) {
                                this.dataExists = false;
                            }
                        }
                    }

                    // VALIDASI INPUT (KOSONG ATAU DUPLIKAT)
                    let validFinal: boolean = this.validateInput(rows_get);

                    if (!validFinal) {
                        // jika tidak valid
                        if (this.temp.length == 0) {
                            this.dataExists = false;
                            this.fill_Null_Object(); // isi dengan NULL supaya filter input muncul
                        }
                        return;
                    }

                    if (validFinal) {
                        // jika valid

                        this.dataExists = true;
                        // FILL DATA
                        tempData = { ...rows_get["data"] };
                        tempData["index"] = this.rows.length;
                        tempData["indexRows"] = this.rows.length;

                        try {
                            // TAMBAHAN OBJECT DATE Sort dengan format YYYYMMDD yang di input secara otomatis ke dateInputSort
                            let obj_date_sort = this.generate_date_sort({ ...tempData }); // generate date sort dengan format YYYYMMDD
                            if (Object.entries(obj_date_sort).length > 0) {
                                tempData = {
                                    ...obj_date_sort, // format date sort (e.g. holidayDate -> holidayDate_Sort)
                                    ...tempData, // original data
                                };
                            }
                        } catch (e) {}

                        this.rows.push(tempData);
                        this.temp.push(tempData);

                        // REVISI
                        this.rows = [...this.rows];
                        this.temp = [...this.temp];
                        this.methodServices.filterTempObject_Merge[this.tableId] = [...this.temp];

                        // TOTAL RECORDS
                        this.totalRecord_String = this.needHitAPI ? this.methodServices.formatNumber(this.totalElements, "decimal") : this.methodServices.formatNumber(this.temp.length, "decimal");

                        // HIDE MODAL JIKA BERHASIL
                        if (typeof rows_get?.["modalDefault"] != "undefined") {
                            rows_get?.["modalDefault"].hide();
                        }

                        this.addAodNSelectedActive();
                        this.filter_Input_Form(this.arrFilterHeader);
                        this.elementGlobal.emit({
                            tableId: this.tableId,
                            tipe: "add_complete",
                            rows: [...this.rows],
                            temp: [...this.temp],
                        });

                        this.methodServices.statusChange = true;
                        // ... end FILL DATA
                    }
                }

                // EDIT DATA
                if (rows_get["tipe"] == "edit") {
                    // VALIDASI INPUT (KOSONG ATAU DUPLIKAT)
                    let validFinal: boolean = this.validateInput(rows_get);

                    if (!validFinal) {
                        // jika tidak valid
                        if (this.temp.length == 0) {
                            this.dataExists = false;
                            this.fill_Null_Object(); // isi dengan NULL supaya filter input muncul
                        }
                        return;
                    }

                    if (validFinal) {
                        // jika valid
                        this.dataExists = true;

                        let dataTemp: any;
                        dataTemp = { ...rows_get?.["data"] };

                        // TAMBAHAN OBJECT DATE Sort dengan format YYYYMMDD yang di input secara otomatis ke dateInputSort
                        let obj_date_sort = this.generate_date_sort({ ...dataTemp }); // generate date sort dengan format YYYYMMDD
                        if (Object.entries(obj_date_sort).length > 0) {
                            dataTemp = {
                                ...obj_date_sort, // format date sort (e.g. holidayDate -> holidayDate_Sort)
                                ...dataTemp, // original data
                            };
                        }

                        // ... end TAMBAHAN OBJECT DATE

                        // FILL DATA
                        // for (let key of Object.keys(rows_get?.['data'])){
                        for (let key of Object.keys(dataTemp)) {
                            this.temp[rows_get?.["rowIndex"]][key] = dataTemp?.[key];

                            let indexRows = this.temp[rows_get?.["rowIndex"]]?.["indexRows"]; // rows index
                            this.rows[indexRows][key] = dataTemp?.[key];
                        }

                        this.rows = JSON.parse(JSON.stringify(this.rows)); // deep clone (independent)
                        this.temp = JSON.parse(JSON.stringify(this.temp)); // deep clone (independent

                        // HAPUS DATA DENGAN VALUE = "NULL" jika ada indikator "deleteKeyNull"
                        if (typeof rows_get?.["deleteKeyNull"] != "undefined") {
                            if (Array.isArray(rows_get?.["deleteKeyNull"])) {
                                // 'deleteKeyNull' berisi array ['colId','balancing']
                                for (let deleteKey of rows_get?.["deleteKeyNull"]) {
                                    let indexRows = this.temp[rows_get?.["rowIndex"]]?.["indexRows"]; // rows index

                                    // DELETE PADA TEMP

                                    let varTemp = this.temp[rows_get?.["rowIndex"]]?.[deleteKey];
                                    if (typeof varTemp != "undefined" && varTemp == null) {
                                        delete this.temp[rows_get?.["rowIndex"]]?.[deleteKey];
                                    }

                                    // DELETE PADA ROWS

                                    let varRows = this.rows[indexRows]?.[deleteKey];
                                    if (typeof varRows != "undefined" && varRows == null) {
                                        delete this.rows[indexRows]?.[deleteKey];
                                    }
                                }
                            }
                        }

                        this.addAodNSelectedActive();

                        // tidak perlu filter semua, cukup splice row saja yang tidak sesuai headerStatus nya
                        if (this.showStatusDropdown) {
                            if (this.headerStatusKey != "" && this.headerStatusKey != null && typeof this.headerStatusKey != "undefined") {
                                // STATUS DARI ROW TABLE (active -> true, false, dst...)
                                let rowStatusvalue = rows_get?.["data"]?.[this.headerStatusKey].toString().toLowerCase();
                                let headerStatusParamValue: any = "";

                                // STATUS DARI HEADER (active -> true, false, dst...)
                                let find_HeaderStatusParamValue = this.headerStatusList.find((res) => res.showText == this.selectedActive);
                                headerStatusParamValue = find_HeaderStatusParamValue["paramValue"].toString().toLowerCase();

                                if (headerStatusParamValue.trim() != "" && rowStatusvalue != headerStatusParamValue) {
                                    // jika tidak sama, langsung di hapus dari array temp
                                    this.temp.splice(rows_get?.["rowIndex"], 1);
                                }

                                if (this.temp.length == 0) {
                                    this.dataExists = false;
                                    this.fill_Null_Object("filter");
                                }
                            }
                        }

                        this.methodServices.filterTempObject_Merge[this.tableId] = [...this.temp];

                        // TOTAL RECORDS
                        this.totalRecord_String = this.needHitAPI ? this.methodServices.formatNumber(this.totalElements, "decimal") : this.methodServices.formatNumber(this.temp.length, "decimal");

                        // HIDE MODAL JIKA BERHASIL
                        if (typeof rows_get?.["modalDefault"] != "undefined") {
                            rows_get?.["modalDefault"].hide();
                        }

                        this.elementGlobal.emit({
                            tableId: this.tableId,
                            tipe: "edit_complete",
                            rows: [...this.rows],
                            temp: [...this.temp],
                        });

                        this.methodServices.statusChange = true;
                        // ... end FILL DATA
                    }
                }
            }
        }
    }

    fixingTableInvisible() {
        let headers = document.querySelectorAll(".datatable-header");
        let bodies = document.querySelectorAll(".datatable-body");
        let table = document.querySelectorAll(".ngx-datatable." + this.tableId);
        let idx: any = 0;

        setTimeout(() => {
            this.rows = [...this.rows];
            this.temp = [...this.temp];
            this.methodServices.filterTempObject_Merge[this.tableId] = [...this.temp];
        }, 100);

        setTimeout(() => {
            for (var i = 0; i < table.length; i++) {
                if (table.item(i).clientWidth == 0) {
                    this.showTable = false;
                    setTimeout(() => {
                        this.showTable = true;
                    }, 50);

                    setTimeout(() => {
                        this.rows = [...this.rows];
                        this.temp = [...this.temp];
                        this.methodServices.filterTempObject_Merge[this.tableId] = [...this.temp];
                    }, 200);
                }
            }
        });
    }

    validateInput(input_obj: any): boolean {
        // input_obj => {}
        // VALIDASI INPUT OBJECT YANG MASUK (MISAL TIDAK BOLEH KOSONG & DUPLIKAT DATA)
        // COMPARE DATA DARI "temp"

        let validFinal: boolean = true;

        if (typeof this.dataInput != "undefined") {
            for (let dataInputObj of this.dataInput) {
                // NAME KOLOM
                let dataInputObj_Name: any = dataInputObj?.["name"];

                // CEK TIDAK BOLEH DATA KOSONG / EMPTY
                if (typeof dataInputObj?.["cellIsEmpty"] != "undefined" && dataInputObj?.["cellIsEmpty"] == false) {
                    // cek object berdasarkan name apakah kosong
                    if (typeof dataInputObj_Name != "undefined") {
                        if (input_obj["data"][dataInputObj_Name.toString()] == "" || input_obj["data"][dataInputObj_Name] == null || typeof input_obj["data"][dataInputObj_Name] == "undefined") {
                            validFinal = false;
                            this.methodServices.showToast(`${dataInputObj?.["label"]} can\'t be blank !`, "error");
                            return validFinal;
                        }
                    }
                }
            }
        }

        //  CEK DATA DUPLICATE, APAKAH SUDAH ADA DI DALAM TABLE "TEMP"
        if (typeof input_obj?.["arrCheckDuplicate"] != "undefined") {
            if (input_obj?.["arrCheckDuplicate"].length > 0) {
                // let kolom_duplicat_gabung:any = '';

                // AMBIL DATA YANG TIDAK VALID

                let findIdx = this.rows.findIndex((row, index) => {
                    let columnDuplicate: any = "";

                    // LOOPING arrCheckDuplicate
                    for (let arrCheckDuplicate of input_obj?.["arrCheckDuplicate"]) {
                        // arrCheckDuplicate => Nama Kolom

                        // ADD DATA
                        if (input_obj?.["tipe"] == "add") {
                            columnDuplicate += ' && "' + row[arrCheckDuplicate] + '"=="' + input_obj?.["data"][arrCheckDuplicate] + '"';
                        } else if (input_obj?.["tipe"] == "edit") {
                            columnDuplicate += ' && "' + row[arrCheckDuplicate] + '"=="' + input_obj?.["data"][arrCheckDuplicate] + '"';
                            // + " && " + index + " != " + input_obj?.['rowIndex']
                        }
                    }
                    // ... end LOOPING arrCheckDuplicate

                    // (KONDISI TIDAK VALID)
                    // TAMBAHKAN PEMBANDING indexRows untuk tipe "EDIT"
                    if (input_obj?.["tipe"] == "edit") {
                        if (columnDuplicate != "" && columnDuplicate.slice(4) != "") {
                            let indexRowsSelected = this.temp[input_obj?.["rowIndex"]]?.["indexRows"];

                            columnDuplicate += " && " + row?.["indexRows"] + " != " + indexRowsSelected;
                        }
                    }

                    // TAMBAHAN INDIKATOR TERDAPAT ACTION != 'DELETE' atau ACTION == 'undefined'
                    // artinya boleh menambah dengan nama yang sama, asalkan action nya sudah ter-delete sebelumnya
                    columnDuplicate += " && " + "(" + "('" + typeof row?.["action"] + "' != 'undefined'" + " && '" + row?.["action"] + "' != 'DELETE'" + ")" + " || '" + typeof row?.["action"] + "' == 'undefined'" + ")";

                    // ... end pembanding indexRows

                    if (columnDuplicate != "" && columnDuplicate.substr(0, 4) == " && ") {
                        columnDuplicate = columnDuplicate.slice(4);
                        if (eval(columnDuplicate)) {
                            validFinal = false;
                            return true;
                        }
                    }
                });

                // JIKA ADA YANG TIDAK VALID
                if (findIdx != -1) {
                    validFinal = false;
                    // kolom_duplicat_gabung = input_obj?.["arrCheckDuplicate"].join(",")
                    this.methodServices.showToast(input_obj?.["arrCheckDuplicate_Msg"], "error");
                    return validFinal;
                }
            }
        }

        return validFinal;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();

        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }

    changeDateFilter(event) {
        let range1: any;
        let range2: any;
        let singleParam_startDate: any;
        let singleParamVal_startDate: any;
        let singleParam_endDate: any;
        let singleParamVal_endDate: any;

        if (this.rangePicker_blockRender) {
            return;
        }

        range1 = event[0];
        range2 = event[1];

        // dapat masuk ke arrFilterHeader & gabung dengan catatan status range picker harus 'true'
        if (this.headerStatusRangePicker) {
            let arrFilter_idx: any;

            singleParam_startDate = this.headerRangePickerKeyStartDate + "=";
            singleParamVal_startDate = this.headerRangePickerKeyStartDate + "=" + (range1 != null ? this.methodServices.formatDate(range1, "yyyy-mm-dd") : "");

            singleParam_endDate = this.headerRangePickerKeyEndDate + "=";
            singleParamVal_endDate = this.headerRangePickerKeyEndDate + "=" + (range2 != null ? this.methodServices.formatDate(range2, "yyyy-mm-dd") : "");

            if (this.arrFilterHeader.length > 0) {
                // jika arrFilterHeader sudah ada data sebelumnya

                // START DATE
                arrFilter_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyStartDate.toString();
                });

                if (arrFilter_idx == -1) {
                    // tidak ketemu, maka ditambahkan langsung
                    if (singleParam_startDate != singleParamVal_startDate) {
                        this.arrFilterHeader.push(singleParamVal_startDate);
                    }
                } else {
                    // ketemu, maka digantikan
                    this.arrFilterHeader[arrFilter_idx] = singleParamVal_startDate;
                }
                // ... end START DATE

                // END DATE
                arrFilter_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyEndDate.toString();
                });

                if (arrFilter_idx == -1) {
                    // tidak ketemu, maka ditambahkan langsung
                    if (singleParam_endDate != singleParamVal_endDate) {
                        this.arrFilterHeader.push(singleParamVal_endDate);
                    }
                } else {
                    // ketemu, maka digantikan
                    this.arrFilterHeader[arrFilter_idx] = singleParamVal_endDate;
                }

                // ... end END DATE

                // jika arrFilterHeader tidak ada data alias kosong
            } else {
                if (singleParam_startDate != singleParamVal_startDate) {
                    this.arrFilterHeader.push(singleParamVal_startDate);
                }
                if (singleParam_endDate != singleParamVal_endDate) {
                    this.arrFilterHeader.push(singleParamVal_endDate);
                }
            }

            if (this.arrFilterHeader.length > 0) {
                this.gabung = this.arrFilterHeader.join("&");

                this.dataTypeServices.arrFilterHeader_Form[this.tableId] = this.arrFilterHeader;
                this.dataTypeServices.gabung_Form[this.tableId] = this.gabung;

                if (this.gabung.substr(0, 1) == "&") {
                    this.gabung = this.gabung.slice(1);
                }

                if (this.gabung != "") {
                    this.gabung = "?" + this.gabung;
                } else {
                    this.gabung = "";
                }

                this.rangePickerChangeGlobal.emit({ param: this.gabung });
            }
        }
    }

    funcHeaderList(headerCode) {
        // All dengan value kosong berarti tidak mengirimkan status sama sekali ke backend

        switch (headerCode) {
            case 0:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "Active",
                        paramValue: "true",
                    },
                    { showText: "Inactive", paramValue: "false" },
                ];
                break;
            case 1:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Completed", paramValue: "Completed" },
                ];
                break;
            case 2:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Approved", paramValue: "Approved" },
                    {
                        showText: "Canceled",
                        paramValue: "Canceled",
                    },
                    { showText: "Rejected", paramValue: "Rejected" },
                ];
                break;
            case 3:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "New Candidate",
                        paramValue: "newcandidate",
                    },
                    { showText: "In Progress", paramValue: "inprogress" },
                    {
                        showText: "Hired",
                        paramValue: "hired",
                    },
                    { showText: "Rejected", paramValue: "rejected" },
                    { showText: "Failed", paramValue: "failed" },
                ];
                break;
            case 4:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Completed", paramValue: "Completed" },
                    { showText: "Error", paramValue: "Error" },
                ];
                break;
            case 5:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Completed", paramValue: "Completed" },
                    {
                        showText: "Cancelled",
                        paramValue: "Cancelled",
                    },
                    { showText: "Error", paramValue: "Error" },
                ];
                break;
            case 6:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Completed", paramValue: "Completed" },
                    {
                        showText: "Rollback",
                        paramValue: "Rollback",
                    },
                    { showText: "Error", paramValue: "Error" },
                ];
                break;
            case 7:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "Draft",
                        paramValue: "Draft",
                    },
                    { showText: "In Progress", paramValue: "In Progress" },
                    {
                        showText: "Pass",
                        paramValue: "Pass",
                    },
                    { showText: "Failed", paramValue: "Failed" },
                    { showText: "Skipped", paramValue: "Skipped" },
                ];
                break;
            case 8:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "New Candidate",
                        paramValue: "New Candidate",
                    },
                    { showText: "In Progress", paramValue: "In Progress" },
                    {
                        showText: "Hired",
                        paramValue: "Hired",
                    },
                    { showText: "Rejected", paramValue: "Rejected" },
                ];
                break;
            case 9:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "Increase",
                        paramValue: "Increase",
                    },
                    { showText: "Decrease", paramValue: "Decrease" },
                ];
                break;
            case 10:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "New",
                        paramValue: "New",
                    },
                    { showText: "In Progress", paramValue: "In Progress" },
                    {
                        showText: "Completed",
                        paramValue: "Completed",
                    },
                    { showText: "Passed", paramValue: "Passed" },
                    { showText: "Failed", paramValue: "Failed" },
                ];
                break;
            case 11:
                this.headerStatusList = [
                    { showText: "All", paramValue: "" },
                    {
                        showText: "In Progress",
                        paramValue: "In Progress",
                    },
                    { showText: "Passed", paramValue: "Passed" },
                    { showText: "Failed", paramValue: "Failed" },
                ];
                break;
        }
    }

    generate_Attribute_Element() {
        let idxEle: number = 0;

        Object.entries(this.dataInput).forEach(([k, v]) => {
            this.dataInput[k]["idx"] = idxEle;
            this.dataInput[k]["idNgTemplate"] = this.tableId + "_id_template" + idxEle.toString();
            this.dataInput[k]["nameNgTemplate"] = this.tableId + "_name_template" + idxEle.toString();
            idxEle++;
        });
    }

    fill_Null_Object(tipeFill?: "filter") {
        // JIKA TIPEnya FILTER, maka "Temp" yang dikosongkan
        // JIKA TIPEnya tidak ada, maka "Rows" & "Temp" yang dikosongkan

        // JIKA DATA KOSONG, MAKA DI PUSH SATU DATA "NULL" SUPAYA FILTER SUMMARY DAPAT MUNCUL
        if (!this.dataExists) {
            let tempArr: any[] = [];
            let newData: any[] = [];

            for (let arr of this.dataInput) {
                newData = [];

                // e.g. [['nama',null], ['paramUrl',null]]
                newData.push(arr["name"]);
                newData.push(null);

                tempArr.push(newData);
            }

            newData = [];
            newData.push("indexRows");
            newData.push(null);
            tempArr.push(newData);

            // Convert ke Object => {'nama':null, 'paramUrl':null}
            let convertObj: any;
            convertObj = Object.fromEntries(tempArr);

            if (tipeFill == "filter") {
                this.temp = [];

                this.temp.push(convertObj);
            } else {
                this.rows = [];
                this.temp = [];

                this.rows.push(convertObj);
                // this.temp = [...this.rows]   // shallow clone (independent) -> hanya independent di level pertama saja, selebihnya saling link
                this.temp = JSON.parse(JSON.stringify(this.rows)); // deep clone (independent)
            }

            this.methodServices.filterTempObject_Merge[this.tableId] = [...this.temp];
        }
    }

    ngOnInit() {
        if (this.configTable?.["tipe"] == "checkbox") {
            this.SelectionType = SelectionType.multiClick;
            this.entries = 5; // secara default limit 5, (e.g. Workflow Approval -> Approval Group -> employeeList )
        } else {
            this.SelectionType = SelectionType.single;
        }

        // GENERATE idx, ID DAN NAME ATTRIBUTE KE SEMUA dataInput
        this.generate_Attribute_Element();

        // isi range date ke rangePicker sebagai value awal yang di tampilkan
        if (this.headerStatusRangePicker) {
            // nilai awal range picker bergantung dari end Date 'headerRangePickerValueEndDate'
            this.bsRangeValue_Start = new Date(this.headerRangePickerValueEndDate.getFullYear(), this.headerRangePickerValueEndDate.getMonth(), this.headerRangePickerValueEndDate.getDate() - this.headerRangePickerMaxDate);

            this.bsRangeValue_End = this.headerRangePickerValueEndDate;

            this.bsRangeValue = [this.bsRangeValue_Start, this.bsRangeValue_End];
        }
        // ... end

        // CALL FUNGSI UNTUK LOAD DATA HEADER LIST (ALL, ACTIVE, INACTIVE, dst ...)
        if (this.headerStatusCode != null && typeof this.headerStatusCode != "undefined") {
            this.funcHeaderList(this.headerStatusCode);
        } else {
            this.funcHeaderList(0);
        }

        // ... end

        // dataInput -> typeSummaryTemplate (text, number, date)

        // kondisi jika parameter arrFilterHeader kosong, tapi di trigger parameter custom dari component luar maka diisi dari luar dulu
        // e.g. changeAOD

        this.dataTypeServices.triggerParamBlank.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            let resultTemp: any;
            let arrFilterTemp: any;

            if (typeof result == "undefined") {
                // jika trigger tanpa parameter / kosong
                this.arrFilterHeader = [];
                this.gabung = "";

                // FORM-GLOBAL
                this.dataTypeServices.arrFilterHeader_Form[this.tableId] = [];
                this.dataTypeServices.gabung_Form[this.tableId] = "";
            } else {
                setTimeout(() => {
                    if (this.arrFilterHeader.length == 0) {
                        resultTemp = result;

                        if (resultTemp.toString().substr(0, 1) == "?") {
                            resultTemp = resultTemp.slice(1);
                        }

                        arrFilterTemp = resultTemp.split("&");
                        this.arrFilterHeader = arrFilterTemp;
                    }
                }, 100);
            }
        });

        // kondisi jika ada update value pada key tertentu di dalam arrFilterHeader & gabung
        // e.g. employeeId=12345 (hanya data single) & case sensitive

        this.dataTypeServices.updateParamKey.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            if (typeof result != "undefined" && result != "") {
                let splitKeyVal = result.toString().split("=");
                let newKey = splitKeyVal[0];
                let newValue = splitKeyVal[1];

                if (this.arrFilterHeader.length > 0) {
                    let findIdx = this.arrFilterHeader.findIndex((arr) => {
                        return arr.toString().split("=")[0] == newKey.toString();
                    });
                    if (findIdx != -1) {
                        this.arrFilterHeader[findIdx] = newKey.toString() + "=" + newValue.toString();
                        this.gabung = this.arrFilterHeader.join("&").toString();

                        // FORM-GLOBAL
                        this.dataTypeServices.arrFilterHeader_Form[this.tableId] = this.arrFilterHeader;
                        this.dataTypeServices.gabung_Form[this.tableId] = this.gabung;
                    }
                }
            }
        });

        let tableId_Int = setInterval(() => {
            if (typeof this.tableId != "undefined" && this.tableId != null && this.tableId != "") {
                this.hideSortTable();
                clearInterval(tableId_Int);
            }
        });
    }

    hideSortTable(time?: any) {
        let timeout: any = time ?? 0;

        setTimeout(() => {
            if (this.hideSortFilterTable != null && typeof this.hideSortFilterTable != "undefined" && this.hideSortFilterTable.length > 0) {
                for (var i = 0; i < this.hideSortFilterTable.length; i++) {
                    this.methodServices.hideSortFilterTable(this.hideSortFilterTable[i], this.tableId);
                }
            }
        }, timeout);
    }

    ngAfterViewInit() {
        this.rangePicker_blockRender = true;
        this.rangePicker_show = true;

        setTimeout(() => {
            this.rangePicker_blockRender = false;
        }, 300);

        // JIKA DATA KOSONG PADA SAAT LOAD AWAL, MAKA DI PUSH DENGAN 'NULL'
        if (this.temp.length == 0) {
            this.dataExists = false;
            this.fill_Null_Object();
        }

        setTimeout(() => {
            this.setScrollEventForFirefox();
        }, 500);

        // jika sudah selesai render tampilan, maka emit tipe 'after_view_init'
    }
    setScrollEventForFirefox() {
        if (this.dataTable) {
            // Below Function to make sure when first initialized datatable always from most left side
            const datatableHeader = this.dataTable.element.querySelector("." + this.tableId + " .datatable-header");
            const datatableBody = this.dataTable.element.querySelector("." + this.tableId + " .datatable-body");

            datatableHeader.scrollLeft = 0;
            datatableBody.scrollLeft = 0;

            //Function to sync datatable header and body when firefox scrolling
            if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
                // Sync scroll positions
                if (this.dataTable) {
                    this.dataTable.element.querySelector("." + this.tableId + " .datatable-body").addEventListener("scroll", () => {
                        datatableHeader.scrollLeft = datatableBody.scrollLeft;
                    });
                }
            }
        }
    }

    ngOnChanges() {
        if (this.statusFinishedApi) {
            //setelah selesai hit api, parsing value kembali ke native element
            this.tempLength = typeof this.temp != "undefined" ? Number(this.temp.length).toLocaleString("id-ID", { style: "decimal" }) : 0;
        }

        if (typeof this.dataInput != "undefined") {
            this.dataInputSort = [];

            for (var i = 0; i < this.dataInput.length; i++) {
                this.dataInputSort.push(this.dataInput[i]["name"]);
            }

            // menambah data sort dengan type = date (_Sort)
            if (typeof this.temp != "undefined") {
                if (this.temp.length > 0) {
                    for (var key in this.temp[0]) {
                        if (key.toString().toLowerCase().indexOf("_sort") != -1) {
                            this.dataInputSort.push(key);
                        }
                    }
                }
            }
        }
    }

    parsing_value_to_input() {
        if (this.arrFilterHeader.length > 0) {
            for (var i = 0; i < this.arrFilterHeader.length; i++) {
                let equalIndex = this.arrFilterHeader[i].indexOf("=");
                let keyIndex: any;
                let valueIndex: any;
                let nameDataInput: any;
                let idNgTemplate: any;
                let typeSummaryTemplate: any;

                if (equalIndex != -1) {
                    keyIndex = this.arrFilterHeader[i].substr(0, equalIndex);
                    valueIndex = this.arrFilterHeader[i].substr(equalIndex + 1, this.arrFilterHeader[i].length);

                    if (this.dataInput.length > 0) {
                        nameDataInput = this.dataInput.filter((result) => {
                            return result["nameParamUrl"].toLowerCase() == keyIndex.toLowerCase();
                        });
                        if (nameDataInput.length > 0) {
                            idNgTemplate = nameDataInput[0]["idNgTemplate"];
                            typeSummaryTemplate = nameDataInput[0]["typeSummaryTemplate"];
                        }

                        setTimeout(() => {
                            var filter1 = document.getElementById(idNgTemplate) as HTMLInputElement;
                            if (typeSummaryTemplate.toLowerCase() == "date") {
                                if (new Date(valueIndex).toString() == "Invalid Date") {
                                    filter1.value = valueIndex;
                                } else {
                                    filter1.value = this.methodServices.formatDate(new Date(valueIndex), "dd-mm-yyyy");
                                }
                            } else {
                                filter1.value = valueIndex;
                            }
                        });
                    }
                }
            }
        }
    }

    onSort($event?: any, column_first?: any, tipe_sort_first?: "asc" | "desc", tipe_sort_column?: any) {
        let x;
        let y;
        let dataSort: boolean;
        let columnFinal: any;
        let tipe_sort_final: any;

        this.status_Sort_Completed = false;

        if ($event == null) {
            // (SORT MANUAL) jika berasal dari sort directive manual, misal sort awal tipe bulk (asc / desc)
            tipe_sort_final = tipe_sort_first;
        } else {
            // (ORIGINAL ICON) jika original dari klik icon sort di header table (asc / desc)
            tipe_sort_final = $event.newValue;
        }

        let typeSortColumn: any;
        if ($event != null) {
            // kondisi original sort (klik icon sort header)
            let findTypeSortColumn = this.dataInput.find((result) => {
                return result["name"].toLowerCase() == $event.column.prop.toLowerCase();
            });
            if (findTypeSortColumn) {
                columnFinal = $event.column.prop;
                typeSortColumn = findTypeSortColumn["typeSortColumn"];

                // sort date
                if (typeSortColumn == "date") {
                    columnFinal = $event.column.prop + "_Sort";
                    let findTypeSortColumn = this.dataInputSort.find((result) => {
                        return result.toLowerCase() == columnFinal.toLowerCase();
                    });
                    if (!findTypeSortColumn) {
                        columnFinal = null;
                    }
                } else {
                    // sort selain date, jika key 'nameSortColumn' tidak ada di dataInputSort, maka ditambahkan ke dalam'y
                    if (typeof findTypeSortColumn["nameSortColumn"] != "undefined") {
                        columnFinal = findTypeSortColumn["nameSortColumn"];

                        let findNameColumn = this.dataInputSort.find((res) => {
                            return res.toLowerCase() == findTypeSortColumn["nameSortColumn"].toLowerCase();
                        });
                        if (!findNameColumn) {
                            this.dataInputSort.push(findTypeSortColumn["nameSortColumn"]);
                        }
                    }
                }
            }
        } else {
            // kondisi sort manual
            columnFinal = column_first;
            typeSortColumn = tipe_sort_column; // number, text, date
        }

        let titleSort = this.dataInputSort;

        if (titleSort.length > 0) {
            dataSort = false;

            titleSort.forEach((colname) => {
                if (tipe_sort_final == "asc") {
                    if (columnFinal.toLowerCase() == colname.toLowerCase()) {
                        if (typeSortColumn == "number" || typeSortColumn == "date") {
                            dataSort = true;
                        }

                        this.temp.sort(function (a, b) {
                            if (typeSortColumn == "number" || typeSortColumn == "date") {
                                return a[colname] - b[colname];
                            } else {
                                x = a[colname].toString().toLowerCase();
                                y = b[colname].toString().toLowerCase();
                                if (x < y) {
                                    dataSort = true;
                                    return -1;
                                }

                                if (x > y) {
                                    dataSort = true;
                                    return 1;
                                }

                                if (x == y) {
                                    return 0;
                                }
                            }
                        });
                        if (dataSort == true) {
                            let temp_sort: any[];
                            temp_sort = JSON.parse(JSON.stringify(this.temp));

                            setTimeout(() => {
                                this.temp = JSON.parse(JSON.stringify(temp_sort));
                                this.status_Sort_Completed = true;
                                this.methodServices.filterTempObject_Merge[this.tableId] = JSON.parse(JSON.stringify(temp_sort));

                                let rows_sort: any[];
                                if ($event == null) {
                                    // JIKA DARI SORTING MANUAL, MAKA ROWS HARUS DI COPY JUGA (ASUMSI HANYA BULK atau kondisi awal)
                                    rows_sort = JSON.parse(JSON.stringify(temp_sort));
                                    this.rows = JSON.parse(JSON.stringify(rows_sort));
                                } else {
                                    rows_sort = JSON.parse(JSON.stringify(this.rows));
                                }

                                this.elementGlobal.emit({
                                    tableId: this.tableId,
                                    tipe: "sort",
                                    rows: JSON.parse(JSON.stringify(rows_sort)),
                                    temp: JSON.parse(JSON.stringify(temp_sort)),
                                });
                            }, 10);
                        }
                    }
                } else if (tipe_sort_final == "desc") {
                    if (columnFinal.toLowerCase() == colname.toLowerCase()) {
                        if (typeSortColumn == "number" || typeSortColumn == "date") {
                            dataSort = true;
                        }

                        this.temp.sort(function (a, b) {
                            if (typeSortColumn == "number" || typeSortColumn == "date") {
                                return b[colname] - a[colname];
                            } else {
                                x = a[colname].toString().toLowerCase();
                                y = b[colname].toString().toLowerCase();
                                if (x < y) {
                                    dataSort = true;
                                    return -1;
                                }

                                if (x > y) {
                                    dataSort = true;
                                    return 1;
                                }

                                if (x == y) {
                                    return 0;
                                }
                            }
                        });
                        if (dataSort == true) {
                            if (typeSortColumn != "number" && typeSortColumn != "date") {
                                this.temp.reverse();
                            }

                            let temp_sort: any[];
                            temp_sort = JSON.parse(JSON.stringify(this.temp));

                            setTimeout(() => {
                                this.temp = JSON.parse(JSON.stringify(temp_sort));
                                this.status_Sort_Completed = true;
                                this.methodServices.filterTempObject_Merge[this.tableId] = JSON.parse(JSON.stringify(temp_sort));

                                let rows_sort: any[];
                                if ($event == null) {
                                    // JIKA DARI SORTING MANUAL, MAKA ROWS HARUS DI COPY JUGA (ASUMSI HANYA BULK atau kondisi awal)
                                    rows_sort = JSON.parse(JSON.stringify(temp_sort));
                                    this.rows = JSON.parse(JSON.stringify(rows_sort));
                                } else {
                                    rows_sort = JSON.parse(JSON.stringify(this.rows));
                                }

                                this.elementGlobal.emit({
                                    tableId: this.tableId,
                                    tipe: "sort",
                                    rows: JSON.parse(JSON.stringify(rows_sort)),
                                    temp: JSON.parse(JSON.stringify(temp_sort)),
                                });
                            }, 10);
                        }
                    }
                }
            });
        }
        return;
    }

    keypressFilter(event, column?, columnType?) {
        let temp: any;
        let tanggal: any;
        let bulan: any;
        let tahun: any;
        let tanggal_join: any;
        let paramRange: any;
        let rangeDate1: any;
        let rangeDate2: any;
        let active: any;

        // jika header RANGE PICKER di aktifkan, maka di isi ke variable "paramRange"
        if (this.headerStatusRangePicker) {
            if (!isNaN(this.bsRangeValue[0]) && !isNaN(this.bsRangeValue[1])) {
                rangeDate1 = this.methodServices.formatDate(this.bsRangeValue[0], "yyyy-mm-dd");
                rangeDate2 = this.methodServices.formatDate(this.bsRangeValue[1], "yyyy-mm-dd");

                // HAPUS DULU START DATE jika ditemukan
                let range1_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyStartDate;
                });
                if (range1_idx != -1) {
                    this.arrFilterHeader.splice(range1_idx, 1);
                }

                // HAPUS DULU END DATE jika ditemukan
                let range2_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyEndDate;
                });
                if (range2_idx != -1) {
                    this.arrFilterHeader.splice(range2_idx, 1);
                }

                // MASUKKIN START DATE & END DATE YANG TERBARU
                this.arrFilterHeader.push(this.headerRangePickerKeyStartDate + "=" + rangeDate1);
                this.arrFilterHeader.push(this.headerRangePickerKeyEndDate + "=" + rangeDate2);
            }
        }

        temp = event.target.value;
        let eventTargetValue: any;
        if (typeof columnType != "undefined" && columnType.toLowerCase() == "date") {
            temp = event.target.value;

            if (temp == "") {
                //tanggal kosong
                eventTargetValue = "";
            } else {
                let arr = temp.split("-");

                tanggal = Number(arr[0]);
                bulan = Number(arr[1]);
                tahun = Number(arr[2]);
                tanggal_join = new Date(tahun, bulan - 1, tanggal);

                if (tanggal_join == "Invalid Date") {
                    eventTargetValue = temp;
                } else {
                    eventTargetValue = this.methodServices.formatDate(tanggal_join, "yyyy-mm-dd");
                }
            }
        } else {
            eventTargetValue = event.target.value;
        }

        if (columnType == "text") {
            eventTargetValue = eventTargetValue.toString().trim();
        }

        if (columnType == "currency") {
            eventTargetValue = eventTargetValue.toString().trim().replace(/[,]/g, "");
            if (eventTargetValue.indexOf(".__") != -1) {
                eventTargetValue = eventTargetValue.toString().trim().replace(/\.__/g, "");
            }
            if (eventTargetValue.indexOf(".00") != -1) {
                eventTargetValue = eventTargetValue
                    .toString()
                    .trim()
                    .replace(/\.[0]{2}/g, "");
            }
        }

        if (columnType == "currency_no_decimal") {
            eventTargetValue = eventTargetValue.toString().trim().replace(/[,]/g, "");
            eventTargetValue = eventTargetValue.toString().trim().replace(/_/g, "");
        }

        if (columnType == "time") {
        }

        let singleParam = column + "=";
        let singleParamVal = column + "=" + eventTargetValue;

        if (this.arrFilterHeader.length == 0) {
            if (singleParam != singleParamVal) {
                this.arrFilterHeader.push(singleParamVal);
            }
        } else {
            let findParam = this.arrFilterHeader.findIndex((result) => {
                return result.toString().split("=")[0] + "=" == singleParam;
            });
            if (findParam != -1) {
                this.arrFilterHeader.splice(findParam, 1);

                if (singleParam != singleParamVal) {
                    this.arrFilterHeader.push(singleParamVal);
                }
            } else {
                if (singleParam != singleParamVal) {
                    this.arrFilterHeader.push(singleParamVal);
                }
            }
        }

        this.gabung = this.arrFilterHeader.join("&");

        // Range Date picker
        if (typeof paramRange != "undefined" && paramRange != "") {
            this.gabung = this.gabung + "&" + paramRange;
        }
        // Sorting

        // function untuk load AOD dan selected active
        this.addAodNSelectedActive();
        // ...end

        if (this.gabung.substr(0, 1) == "&") {
            this.gabung = this.gabung.slice(1);
        }

        if (this.gabung != "") {
            this.gabung = "?" + this.gabung;
        } else {
            this.gabung = "";
        }

        // FORM-GLOBAL
        this.dataTypeServices.arrFilterHeader_Form[this.tableId] = this.arrFilterHeader;
        this.dataTypeServices.gabung_Form[this.tableId] = this.gabung;
        if (event.key == "Enter") {
            if (!this.needHitAPI) {
                this.filter_Input_Form(this.arrFilterHeader);
            } else {
                // hit api
                // FILTER HASIL INPUT
                this.currentPage = 1;
                this.numberIndexing = 1;
                this.dataTable.offset = 0;
                this.elementGlobal.emit({
                    tableId: this.tableId,
                    tipe: "search_input",
                    paramsFilter: this.gabung,
                    page: { currentPage: this.currentPage - 1, size: this.entries },
                });
            }
        }
    }

    filter_Input_Form(arrFilter: any) {
        if (this.rows.length == 1 || this.temp.length == 1) {
            // FILTER DATA YANG TIDAK KOSONG DENGAN INDIKATOR indexRows == 'null', karena faktor "no data to display" ter-create satu data null
            let find_null_Index_Rows = this.temp.find((tmp) => tmp?.["indexRows"] == null || typeof tmp?.["indexRows"] == "undefined");
            if (find_null_Index_Rows) {
                this.rows = this.rows.filter((row) => {
                    return row?.["indexRows"] != null && typeof row?.["indexRows"] != "undefined";
                });
                this.temp = [...this.rows];
            }
        }

        if (this.rows.length > 0) {
            this.temp = this.rows.filter((row) => {
                // try{
                if (typeof arrFilter != "undefined" && arrFilter instanceof Object && Array.isArray(arrFilter)) {
                    let validFilter: any = ""; // khusus yang kolom yang valid yang akan di filter

                    // LOOPING DARI arrFilterHeader (data yang akan di filter)
                    arrFilter.forEach((val, idx, arr) => {
                        let split: any[] = val.split("=");
                        let split_Key: any = split.length > 0 ? split[0] : undefined; // get Key    'keyExample:valExample' => 'keyExample'
                        let split_Value: any = split.length > 1 ? split[1] : undefined; // get Value  'keyExample:valExample' => 'valExample'

                        // KUMPULKAN KEY YANG SESUAI DENGAN ROW TERLEBIH DAHULU
                        if (typeof row?.[split_Key] != "undefined") {
                            // jika Value tidak kosong

                            let existsType: boolean = false;

                            // COMPARE DENGAN TIPE "DATE"
                            let find_TypeSummaryTemplate = this.dataInput.find((arr) => arr["nameParamUrl"] == split_Key);
                            if (find_TypeSummaryTemplate) {
                                try {
                                    if (find_TypeSummaryTemplate.typeSummaryTemplate != null && typeof find_TypeSummaryTemplate.typeSummaryTemplate != undefined) {
                                        if (find_TypeSummaryTemplate.typeSummaryTemplate.toLowerCase() == "date") {
                                            existsType = true;
                                            // replace semua yang "d/m/y" ke "[0-9]"
                                            let pattern: any = split_Value.replace(/d/g, "[0-9]").replace(/m/g, "[0-9]").replace(/y/g, "[0-9]");
                                            let pattern_Format: any = new Date(pattern);
                                            let regexp: any;

                                            if (!isNaN(pattern_Format)) {
                                                // jika valid date, maka di convert ke "dd-mm-yyyy", karena jika valid formatnya secara default dari "yyyy-mm-dd"
                                                regexp = new RegExp(this.methodServices.formatDate(pattern_Format, "dd-mm-yyyy"), "g");
                                            } else {
                                                regexp = new RegExp(pattern, "g");
                                            }

                                            if (typeof row?.[split_Key] != "undefined") {
                                                // valid "Tanggal"
                                                let split_Value_Format: any = new Date(row?.[split_Key]);
                                                if (!isNaN(split_Value_Format)) {
                                                    validFilter += " && " + regexp.test(this.methodServices.formatDate(new Date(row?.[split_Key]), "dd-mm-yyyy"));
                                                } else {
                                                    validFilter += " && false";
                                                }
                                            }
                                        }

                                        if (find_TypeSummaryTemplate.typeSummaryTemplate.toLowerCase() == "time") {
                                            existsType = true;

                                            let pattern: any = split_Value.replace(/h/g, "[0-9]").replace(/m/g, "[0-9]");
                                            let regexp: any;

                                            regexp = new RegExp(pattern, "g");

                                            if (typeof row?.[split_Key] != "undefined") {
                                                validFilter += " && " + regexp.test(row?.[split_Key]);
                                            }
                                        }

                                        if (
                                            find_TypeSummaryTemplate.typeSummaryTemplate.toLowerCase().indexOf("currency") != -1 ||
                                            find_TypeSummaryTemplate.typeSummaryTemplate.toLowerCase().indexOf("number_decimal") != -1 ||
                                            find_TypeSummaryTemplate.typeSummaryTemplate.toLowerCase().indexOf("number_decimal_dynamic") != -1
                                        ) {
                                            existsType = true;

                                            // (split value) 1,000,000.07 => 1000000,07
                                            // data yang di filter
                                            let pattern: any = +split_Value.replace(/\,/g, "");

                                            let regexp: any;
                                            regexp = new RegExp(pattern, "g");

                                            if (typeof row?.[split_Key] != "undefined") {
                                                // data pada table
                                                let row_table = +row?.[split_Key].toString().replace(/\./g, "").replace(/,/g, ".");
                                                validFilter += " && " + regexp.test(row_table);
                                            }
                                        }

                                        if (find_TypeSummaryTemplate.typeSummaryTemplate.toLowerCase() == "dropdown") {
                                            existsType = true;

                                            // CHECK jika value filter nya undefined, maka di replace dengan ('')
                                            let split_value_temp: any = "";

                                            if (typeof split_Value == "undefined") {
                                                split_value_temp = "";
                                            } else {
                                                split_value_temp = split_Value;
                                            }

                                            if (typeof row?.[split_Key] != "undefined") {
                                                validFilter += " && '" + row?.[split_Key].toString().toLowerCase() + "' == '" + split_value_temp.toLowerCase() + "'";
                                            }
                                        }
                                    }
                                } catch (e) {
                                    existsType = false;
                                    this.methodServices.showToast('!!!FOR TECHNICAL (ngx-datatable-form-global) \n (Function) : "filter_input_form" \n\n' + e, "warning");
                                }
                            }
                            // ... end COMPARE TIME

                            if (!existsType) {
                                if (typeof row?.[split_Key] == "boolean") {
                                    validFilter += " && " + row[split_Key] + " == " + split_Value;
                                }
                                if (typeof row?.[split_Key] == "string") {
                                    validFilter += " && " + '"' + row[split_Key].toLowerCase().replace(/\\/gi, "\\\\") + '"' + '.indexOf("' + split_Value.toLowerCase().replace(/\\/gi, "\\\\") + '") != -1';
                                }
                                if (typeof row?.[split_Key] == "number") {
                                    validFilter += " && " + '"' + row[split_Key].toString() + '"' + '.indexOf("' + split_Value + '") != -1';
                                }
                            }
                        }
                    });

                    // ... end LOOPING arrFilterHeader

                    // TAMBAHKAN VALIDASI DATA YANG ACTION == 'DELETE' TIDAK BOLEH DITAMPILKAN
                    validFilter += " && " + "(" + "('" + typeof row?.["action"] + "' != 'undefined' && '" + row?.["action"] + "' != 'DELETE'" + ")" + " || '" + typeof row?.["action"] + "' == 'undefined')";
                    // ... end

                    // NORMALIZE STRING with replace \n to blank
                    validFilter = validFilter.replace(/(\n|\r)/gm, "");
                    // ... end

                    if (validFilter != "" && validFilter.substr(0, 4) == " && ") {
                        validFilter = validFilter.slice(4);
                        if (eval(validFilter)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (validFilter == "") {
                        return true;
                    }
                }
            });
        }
        // ... end rows.length > 0

        this.methodServices.filterTempObject_Merge[this.tableId] = [...this.temp];

        this.totalRecord_String = this.needHitAPI ? this.methodServices.formatNumber(this.totalElements, "decimal") : this.methodServices.formatNumber(this.temp.length, "decimal");

        if (this.temp.length == 0) {
            this.dataExists = false;
            this.fill_Null_Object("filter");
        } else {
            this.dataExists = true;
        }

        // sini

        if (typeof this.tableId != "undefined" && this.tableId != null && this.tableId != "") {
            this.hideSortTable();
        }
        this.elementGlobal.emit({ tableId: this.tableId, tipe: "filter", rows: [...this.rows], temp: [...this.temp] });
    }

    addAodNSelectedActive() {
        let active: any;

        if (this.statusAODParamUrl) {
            let aod: any;

            try {
                if (typeof this.modelEffectiveDateFinal == "string") {
                    this.modelEffectiveDateFinal = new Date(this.modelEffectiveDate);
                }

                aod = this.methodServices.formatDate(this.modelEffectiveDateFinal, "YYYY-MM-DD");
            } catch (e) {
                this.methodServices.showToast("(ngx-datatable-form-global -> addAodNSelectedActive) " + e, "error");
            }

            if (typeof aod != "undefined") {
                if (this.gabung != "" && typeof this.gabung != "undefined" && this.gabung != null) {
                    // cek apakah aod sudah ada di variable gabung, jika tidak ada baru di tambah aod nya, jika ada maka di skip
                    let findAodinGabung = this.gabung.split("&").findIndex((result) => {
                        // return result.toLowerCase().indexOf("aod=") != -1
                        return result.toLowerCase().split("=")[0] + "=" == "aod=" || result.toLowerCase().split("=")[0] + "=" == "?aod=";
                    });
                    if (findAodinGabung == -1) {
                        active = "aod=" + aod;
                    } else {
                        // jika ada aod, maka harus dihapus dari (this.gabung) dulu, baru update aod yg baru
                        let gabungTempArr: any;
                        let gabungTemp: any;

                        gabungTempArr = this.gabung.split("&");
                        gabungTempArr.splice(findAodinGabung, 1);

                        gabungTemp = gabungTempArr.join("&");

                        if (gabungTemp != "" && gabungTemp != null && typeof gabungTemp != "undefined") {
                            this.gabung = gabungTemp;
                        } else {
                            this.gabung = "";
                        }

                        active = "aod=" + aod;
                    }
                    // ... end
                } else {
                    active = "aod=" + aod;
                }

                // PUSH aod KE arrFilterHeader
                if (this.arrFilterHeader.length > 0) {
                    let findArrFilter = this.arrFilterHeader.findIndex((result) => {
                        return result.toString().toLowerCase().split("=")[0] + "=" == "aod=";
                    });
                    if (findArrFilter >= 0) {
                        this.arrFilterHeader.splice(findArrFilter, 1);
                    }
                }
                this.arrFilterHeader.push("aod=" + aod);
                // ... END PUSH aod
            }
        }

        if (typeof active == "undefined") {
            active = "";
        }

        if (this.showStatusDropdown) {
            // hapus active dari arrFilterHeader
            let findArrFilter = this.arrFilterHeader.findIndex((result) => {
                return result.toString().toLowerCase().split("=")[0] + "=" == this.headerStatusKey.toString().toLowerCase() + "=";
            });

            if (findArrFilter >= 0) {
                this.arrFilterHeader.splice(findArrFilter, 1);
            }
            // ... END hapus active
        }

        let activeTemp: any = "";

        if (this.showStatusDropdown) {
            let findActiveTemp: any;

            findActiveTemp = this.headerStatusList.find((result) => {
                return result["showText"].toLowerCase() == this.selectedActive.toLowerCase();
            });

            if (findActiveTemp) {
                if (findActiveTemp["paramValue"] != "") {
                    activeTemp = "&" + this.headerStatusKey + "=";
                    activeTemp += findActiveTemp["paramValue"];
                    this.arrFilterHeader.push(this.headerStatusKey + "=" + findActiveTemp["paramValue"]);
                }
            }
        }

        if (this.gabung != "" && typeof this.gabung != "undefined" && this.gabung != null) {
            // cek apakah active sudah ada di variable gabung, jika tidak ada baru di tambah active nya, jika ada maka di skip
            let findAodinGabung = this.gabung.split("&").findIndex((result) => {
                return result.toLowerCase().split("=")[0] + "=" == this.headerStatusKey + "=";
            });
            if (findAodinGabung == -1) {
                active += activeTemp;
            }
            // ... end
        } else {
            active += activeTemp;
        }

        if (typeof active != "undefined" && active != "") {
            let gabungActiveTemp: any = "";

            gabungActiveTemp = typeof this.gabung != "undefined" && this.gabung != "" && this.gabung != null ? this.gabung : "";

            if (active.substr(0, 1) == "&") {
                this.gabung = gabungActiveTemp + active;
            } else {
                this.gabung = gabungActiveTemp + "&" + active;
            }
        }
    }

    createElement() {
        // emit ke depan
        this.elementGlobal.emit({ tableId: this.tableId, tipe: "add" });
        // kosongkan arrFilterHeader di dataTypeServices di awal masuk form..
        // ...end

        // FORM-GLOBAL
        this.dataTypeServices.arrFilterHeader_Form[this.tableId] = [];
        this.dataTypeServices.gabung_Form[this.tableId] = "";
    }

    formProc(rowIndex) {
        let selected: any = {};
        if (this.temp.length > 0) {
            selected = this.temp[rowIndex];

            this.elementGlobal.emit({
                tableId: this.tableId,
                tipe: "form_proc",
                rowIndex: rowIndex,
                selectedRow: { ...selected },
            });

            // kosongkan arrFilterHeader di dataTypeServices di awal masuk form..

            // ...end

            // FORM-GLOBAL
            this.dataTypeServices.arrFilterHeader_Form[this.tableId] = [];
            this.dataTypeServices.gabung_Form[this.tableId] = "";
        }
    }

    elementClassLoad() {
        // emit ke depan
        if (this.needHitAPI) {
            this.dataTable.offset = 1;
            this.currentPage = 1;
            this.elementGlobal.emit({
                tableId: this.tableId,
                tipe: "refresh",
                paramsFilter: this.gabung,
                page: { currentPage: this.currentPage - 1, size: this.entries },
            });
        } else {
            this.elementGlobal.emit({ tableId: this.tableId, tipe: "refresh" });
        }
        if (typeof this.dataTable != "undefined") {
            this.dataTable.sorts = [];
        }
    }

    entriesChange($event) {
        this.spinner.show();
        this.entries = Number($event.target.value);
        if (this.needHitAPI) {
            this.currentPage = 1;
            this.dataTable.offset = 1;
            this.elementGlobal.emit({
                tableId: this.tableId,
                tipe: "change_entries",
                paramsFilter: this.gabung,
                page: { currentPage: this.currentPage - 1, size: this.entries },
            });
        }
        this.spinner.hide();
    }

    selectedActiveProc(event) {
        // emit ke depan
        this.selectedActive = event["showText"].substring(0, 1).toUpperCase() + event["showText"].slice(1);

        this.selectProc(event);
    }

    selectProc(event) {
        let param: any;
        let paramRange: any; // filter header

        this.addAodNSelectedActive();

        this.gabung = this.arrFilterHeader.join("&");

        // Range Date picker
        if (typeof paramRange != "undefined" && paramRange != "") {
            this.gabung = this.gabung + "&" + paramRange;
        }
        // active : true / false / all ('')
        if (typeof param != "undefined" && param != "") {
            this.gabung = this.gabung + "&" + param;
        }

        if (this.gabung.substr(0, 1) == "&") {
            this.gabung = this.gabung.slice(1);
        }
        if (this.gabung != "") {
            this.gabung = "?" + this.gabung;
        } else {
            this.gabung = "";
        }

        if (this.needHitAPI) {
            this.elementGlobal.emit({
                tableId: this.tableId,
                tipe: "search_input",
                paramsFilter: this.gabung,
                page: { currentPage: this.currentPage - 1, size: this.entries },
            });
        } else {
            // FILTER KEMBALI SETELAH SELECT PROC BERDASARKAN PARAMETER INPUT
            this.filter_Input_Form(this.arrFilterHeader);
        }

        if (typeof this.dataTable != "undefined") {
            this.dataTable.sorts = [];
        }

        // emit ke depan
    }

    showHierarchy(rowIndex) {
        let selected: any = {};
        if (this.temp.length > 0) {
            selected = this.temp[rowIndex];

            this.showHierarchyGlobal.emit(selected);
        }
    }

    editShow(rowIndex) {
        this.elementGlobal.emit({ tableId: this.tableId, tipe: "edit", rowIndex });
    }

    deleteShow(rowIndex) {
        let idxRows = this.temp[rowIndex]["indexRows"]; // index "Rows"
        let idxTemp = rowIndex; // index "Temp"

        // CEK jika ada cellKeyId, maka harus ditambahkan action = "DELETE"
        if (typeof this.cellKeyId != "undefined" && this.cellKeyId != "" && this.cellKeyId != null) {
            if (typeof this.rows[idxRows]?.[this.cellKeyId] != "undefined") {
                this.rows[idxRows]["action"] = "DELETE";
            } else {
                this.rows[idxRows]["action"] = "DELETE";

                // "action_delete_send" tanpa "cellKeyId" sebagai indikator row yang akan dihapus pada saat di kirim depan
                this.rows[idxRows]["action_delete_send"] = true;
            }
        } else {
            this.methodServices.showToast("(FOR TECHNICAL) Key ID for Delete is blank, Record will be removed permanently from 'Rows'", "warning");
            this.rows[idxRows]["action"] = "DELETE";

            // "action_delete_send" tanpa "cellKeyId" sebagai indikator row yang akan dihapus pada saat di kirim depan
            this.rows[idxRows]["action_delete_send"] = true;
        }

        this.temp.splice(idxTemp, 1);

        this.totalRecord_String = this.needHitAPI ? this.methodServices.formatNumber(this.totalElements, "decimal") : this.methodServices.formatNumber(this.temp.length, "decimal");

        // KIRIM KE DEPAN DENGAN SYARAT TIDAK ADA ACTION_DELETE_SEND ATAU false
        this.rows_send = JSON.parse(
            JSON.stringify(
                this.rows.filter((row) => {
                    return typeof row?.["action_delete_send"] == "undefined" || (typeof row?.["action_delete_send"] != "undefined" && !row?.["action_delete_send"]);
                })
            )
        );

        this.elementGlobal.emit({
            tableId: this.tableId,
            tipe: "delete_complete",
            rows: [...this.rows_send],
            temp: [...this.temp],
        });

        if (this.temp.length == 0) {
            this.dataExists = false;

            // "filter" -> hanya array "temp" saja yang di kosongkan dan isi null
            this.fill_Null_Object("filter");
        }

        this.methodServices.statusChange = true;
    }

    reprocessFunc(rowIndex) {
        this.reprocessFuncGlobal.emit(rowIndex);
    }

    rollbackFunc(rowIndex) {
        this.rollbackFuncGlobal.emit(rowIndex);
    }

    downloadViewLog(idx) {
        window.open(this.temp[idx].logFile);
    }

    downloadLog(idx) {
        let urlFle = this.temp[idx].logFile;
        window.open(urlFle);
    }

    downloadLogView(idx, attr) {
        this.downloadLogViewGlobal.emit(idx, attr);
    }

    download(idx, attr) {
        let urlFle = attr == "report" ? this.temp[idx].reportUrl : this.temp[idx].logFile;
        window.open(urlFle);
    }

    focusProc(event) {
        this.positionCurrentId = event.target.id;
    }

    funcNumberMask(placeholder?) {
        this.numberInputMask = createMask({
            alias: "numeric",
            groupSeparator: "",
            digits: 0,
            digitsOptional: false,
            prefix: "",
            placeholder: placeholder,
            allowMinus: false,
        });
    }

    selectedValueOutput(val, column?) {
        let gabungTemp: any;
        let gabungTempArr: any;
        let paramSingle: any;
        let param: any;
        let valTemp: any;

        let rangeDate1: any;
        let rangeDate2: any;

        // function untuk load AOD dan selected active
        this.addAodNSelectedActive();
        // ...end

        // jika header RANGE PICKER di aktifkan, maka di isi ke variable "paramRange"
        if (this.headerStatusRangePicker) {
            if (!isNaN(this.bsRangeValue[0]) && !isNaN(this.bsRangeValue[1])) {
                rangeDate1 = this.methodServices.formatDate(this.bsRangeValue[0], "yyyy-mm-dd");
                rangeDate2 = this.methodServices.formatDate(this.bsRangeValue[1], "yyyy-mm-dd");

                // HAPUS DULU START DATE jika ditemukan
                let range1_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyStartDate;
                });
                if (range1_idx != -1) {
                    this.arrFilterHeader.splice(range1_idx, 1);
                }

                // HAPUS DULU END DATE jika ditemukan
                let range2_idx = this.arrFilterHeader.findIndex((result) => {
                    return result.toString().split("=")[0] == this.headerRangePickerKeyEndDate;
                });
                if (range2_idx != -1) {
                    this.arrFilterHeader.splice(range2_idx, 1);
                }

                // MASUKKIN START DATE & END DATE YANG TERBARU
                this.arrFilterHeader.push(this.headerRangePickerKeyStartDate + "=" + rangeDate1);
                this.arrFilterHeader.push(this.headerRangePickerKeyEndDate + "=" + rangeDate2);
            }
        }
        // ... end RANGE PICKER

        paramSingle = column + "=";

        if (val != "" && typeof val != "undefined" && val != null) {
            valTemp = val["paramUrl"];

            param = column + "=" + valTemp;

            if (this.arrFilterHeader.length > 0) {
                // BERSIHKAN DATA YANG KOSONG / NULL / UNDEFINED
                let arrClean = this.arrFilterHeader.filter(Boolean);
                this.arrFilterHeader = [...arrClean];

                this.gabung = this.arrFilterHeader.join("&");
            }

            if (typeof this.gabung != "undefined" && this.gabung != null && this.gabung != "") {
                if (this.gabung.substr(0, 1) == "?") {
                    gabungTemp = this.gabung.slice(1);
                } else {
                    gabungTemp = this.gabung;
                }

                gabungTempArr = gabungTemp.split("&");

                // gabungTempArr
                let findGabungTempArr_Index = gabungTempArr.findIndex((result) => {
                    return result.toLowerCase().split("=")[0] + "=" == paramSingle.toLowerCase();
                });

                if (findGabungTempArr_Index >= 0) {
                    gabungTempArr.splice(findGabungTempArr_Index, 1);
                }

                // arrFilterHeader
                let findArrFilterHeader_Index = this.arrFilterHeader.findIndex((result) => {
                    return result.toLowerCase().split("=")[0] + "=" == paramSingle.toLowerCase();
                });
                if (findArrFilterHeader_Index >= 0) {
                    this.arrFilterHeader.splice(findArrFilterHeader_Index, 1);
                }

                if (param != paramSingle) {
                    gabungTempArr.push(param);
                    this.arrFilterHeader.push(param);
                }
                this.gabung = gabungTempArr.join("&");
            } else {
                if (param != paramSingle) {
                    this.gabung = param;
                    this.arrFilterHeader.push(param);
                }
            }

            // emit keluar (trigger keypressglobal)
            if (this.gabung != "") {
                this.gabung = "?" + this.gabung;
            } else {
                this.gabung = "";
            }

            // FORM-GLOBAL
            this.dataTypeServices.arrFilterHeader_Form[this.tableId] = this.arrFilterHeader;
            this.dataTypeServices.gabung_Form[this.tableId] = this.gabung;
        } else {
            // KONDISI JIKA (VAL) KOSONG KARENA DELETE ATAU CLICK CLEAR BUTTON

            if (this.arrFilterHeader.length > 0) {
                this.gabung = this.arrFilterHeader.join("&");
            }

            if (typeof this.gabung != "undefined" && this.gabung != null && this.gabung != "") {
                if (this.gabung.substr(0, 1) == "?") {
                    gabungTemp = this.gabung.slice(1);
                } else {
                    gabungTemp = this.gabung;
                }

                gabungTempArr = gabungTemp.split("&");

                // gabungtemparr
                let findGabungTempArr_Index = gabungTempArr.findIndex((result) => {
                    return result.toLowerCase().split("=")[0] + "=" == paramSingle.toLowerCase();
                });
                if (findGabungTempArr_Index >= 0) {
                    gabungTempArr.splice(findGabungTempArr_Index, 1);
                }

                // arrFilterHeader
                let findArrFilterHeader_Index = this.arrFilterHeader.findIndex((result) => {
                    return result.toLowerCase().split("=")[0] + "=" == paramSingle.toLowerCase();
                });
                if (findArrFilterHeader_Index >= 0) {
                    this.arrFilterHeader.splice(findArrFilterHeader_Index, 1);
                }

                if (gabungTempArr.length > 0) {
                    this.gabung = gabungTempArr.join("&");
                } else {
                    this.gabung = "";
                }

                // emit keluar (trigger keypressglobal)
                if (this.gabung != "") {
                    this.gabung = "?" + this.gabung;
                } else {
                    this.gabung = "";
                }

                // FORM-GLOBAL
                this.dataTypeServices.arrFilterHeader_Form[this.tableId] = this.arrFilterHeader;
                this.dataTypeServices.gabung_Form[this.tableId] = this.gabung;
            }
        }

        this.filter_Input_Form(this.arrFilterHeader);
    }

    viewTemplate() {
        this.viewTemplateGlobal.emit();
    }

    onResize(event) {
        let resIdx = this.dataInput.findIndex((result) => result["name"] == event.column.prop);
        if (resIdx) {
            this.dataInput[resIdx]["width"] = event.newValue;
        }

        this.hideSortTable();

        this.table_AdjustSizeHeaderBody();

        // PARSING KEMBALI DATA YANG DI FILTER KE INPUT ELEMENT
        this.parsing_value_to_input();
    }

    table_AdjustSizeHeaderBody() {
        // UNTUK CUT OFF ISSUE TABLE (HEADER & BODY NOT MATCH)

        setTimeout(() => {
            let headerCell = document.querySelectorAll("." + this.tableId + " .datatable-header-cell");
            let bodyCell_RowWrapper = document.querySelectorAll("." + this.tableId + " .datatable-row-wrapper")[0];
            for (var i = 0; i < headerCell.length; i++) {
                // TEXT DARI HEADER NAMA KOLOM
                let headerCell_TemplateWrap = headerCell.item(i).querySelector("." + this.tableId + " .datatable-header-cell-template-wrap span");

                // GET ALL BODY CELL
                let bodyCell_RowWrapper_cell = bodyCell_RowWrapper.querySelectorAll("." + this.tableId + " .datatable-body-cell");

                // ADJUST WIDTH HEADER DARI BODY CELL JIKA TIDAK SESUAI ANTARA HEADER DAN BODY
                if (headerCell.item(i).clientWidth < bodyCell_RowWrapper_cell.item(i).clientWidth) {
                    (<HTMLElement>headerCell.item(i)).style.width = bodyCell_RowWrapper_cell.item(i).clientWidth.toString() + "px";
                }
            }
        }, 100);
    }

    closeButton() {
        if (typeof this.configTable?.["modal"]?.["modalDefault"] != "undefined") {
            // JIKA ADA MODAL DIRECTIVE YANG DI MASUKKAN KE GLOBAL
            this.configTable?.["modal"]?.["modalDefault"].hide();
        } else {
            this.elementGlobal.emit({ tableId: this.tableId, tipe: "close_modal" });
        }
    }

    onSelectList({ selected }) {
        if (typeof selected[selected.length - 1]?.["indexRows"] != undefined && selected[selected.length - 1]?.["indexRows"] != null) {
            this.selectedListCheckbox.splice(0, this.selectedListCheckbox.length);
            this.selectedListCheckbox.push(...selected);
        } else {
            this.selectedListCheckbox = [];
        }

        this.elementGlobal.emit({
            tableId: this.tableId,
            tipe: "select_list",
            selected_array: [...this.selectedListCheckbox],
        });
    }

    onCheckboxChangeFn($event) {}

    selectFn($event) {}

    onActivate(event: any, modal?: any) {
        this.activeRow = event.row;
        if (event.type == "checkbox") {
            // event.event.stopPropagation()
        }
    }

    sortFirst_Load() {
        // kondisi sorting pertama kali pada saat form sedang di render

        let sortFirst = this.configTable?.["sortFirst"];
        let column = this.configTable?.["sortFirst"]?.["column"];
        let tipe_sort = this.configTable?.["sortFirst"]?.["tipe"];
        let typeSortColumn = this.configTable?.["sortFirst"]?.["typeSortColumn"]; // number, text, date

        let columnFinal: any = "";
        let tipe_sort_column: any = "text"; // text atau nontext

        if (typeof sortFirst != "undefined") {
            // HANYA JIKA TER-IDENTIFIKASI ADA sortFirst, maka diproses.

            if (typeof column == "undefined") {
                this.methodServices.showToast("!!!(FOR TECHNICAL) Column Sort is undefined !", "warning");
                return;
            }
            if (typeof tipe_sort == "undefined") {
                this.methodServices.showToast("!!!(FOR TECHNICAL) Tipe (asc / desc) is undefined !", "warning");
                return;
            }

            // CHECK JIKA typeSummaryTemplate == 'date', maka sorting yang ada suffix-nya ("_Sort")
            let _find = this.dataInput.find((dtInput) => {
                return dtInput?.["name"].toLowerCase() == column.toLowerCase();
            });

            if (_find) {
                tipe_sort_column = _find?.["typeSortColumn"];

                if (_find?.["typeSummaryTemplate"] == "date") {
                    let suffix_sort = _find?.["name"] + "_Sort";

                    let _find_dtInput_sort = this.dataInputSort.find((dtInputSort) => {
                        return dtInputSort.toLowerCase() == suffix_sort.toLowerCase();
                    });
                    if (_find_dtInput_sort) {
                        columnFinal = _find_dtInput_sort;
                    } else {
                        columnFinal = column;
                    }
                } else {
                    columnFinal = column;
                }
            } else {
                columnFinal = column;
            }

            this.onSort(null, columnFinal, tipe_sort, tipe_sort_column);
        } else {
            this.status_Sort_Completed = true;
            // JIKA TIDAK ADA SORT FIRST, MAKA TER-INDIKASI TIDAK PERLU SORT AWAL
            return;
        }
    }

    changePagination(event) {
        this.dataTable.limit = this.entries;
        this.currentPage = event.page;
        this.dataTable.offset = event.page - 1;

        this.elementGlobal.emit({
            tableId: this.tableId,
            tipe: "change_page",
            paramsFilter: this.gabung,
            page: { currentPage: this.currentPage - 1, size: this.entries },
        });
    }
}
