import { Component, OnInit } from '@angular/core';
import { MethodServices } from 'src/services/method-services'; //utama
import { Router } from '@angular/router';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {

  message:any

  constructor(
    private methodServices: MethodServices,
    private router: Router
  ) {}


  ngOnInit() {
    for (let arr = 0; arr < localStorage.length; arr++) {
      let getKey = localStorage.key(arr)
      // let decryptKey = this.methodServices.ivSha256Decrypt(getKey)

      if (getKey == 'GITMAIN'){
        let getData = localStorage.getItem(getKey)
        let decryptValue = this.methodServices.ivSha256Decrypt(getData)
        let data = JSON.parse(decryptValue)
        this.message = data.error.error
      }

      // if (decryptKey == 'maintenanceModeMsg') {
      //   let getData = localStorage.getItem(getKey)
      //   let decryptValue = this.methodServices.ivSha256Decrypt(getData)
      //   let data = JSON.parse(decryptValue)
      //   this.message = data.error.error
      // }
    }
  }
  back(){
    this.router.navigate(["home/profile"]);
  }
}
