import { Injectable, OnDestroy, Injector } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { timeout, takeUntil } from "rxjs/operators";
import { DecimalPipe, Location } from "@angular/common";

// import * as CryptoJS from 'crypto-js'
import { JwtHelperService } from "@auth0/angular-jwt";

import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
// import swal from 'sweetalert2'
import { DataTypeServices } from "./data-type-services";
import { subject_sidebar_userprofile_status } from "../app/components/sidebar/sidebar.component";

const CryptoJS = require("crypto-js");
const swal = require("sweetalert2");

@Injectable({ providedIn: "root" })
export class MethodServices implements OnDestroy {
    statusChange: boolean = false;

    aktif_table = new Subject<boolean>();

    aod = new Subject<any>();
    aodPrev = new Subject<any>();
    effDate: any; //effective date
    firstLoad: boolean = true;
    firstLoadInc: number = 0;
    selectedActive: any;
    companyLogo: any;
    companyId: any = null;
    imageLoad: boolean;
    arrBreadcrumbSubject = new Subject<any[]>();
    recruitmentKeyjson = new Subject<any>();
    openTopNav = new Subject<boolean>();

    employeInformation = new Subject<any>();
    employeeProfilePicture = new Subject<string>();

    jwt_keyjson: any;
    jwt_access_token: any;
    jwt_refresh_token: any;
    jwt_tenant_id: any;
    jwt_authorities_key: any;
    jwt_authorities_value: any;
    jwt_authorities: any = [];
    jwt_instance_api: any;
    jwt_role_user: any;

    layoutStyleTime: string = "MORNING";

    maintenanceMode: boolean = false;

    filterTemp = new Subject<any>();
    filterTempObject = [];

    triggerRemoveDirty = new Subject<any>();

    // (NGX-DATATABLE-FORM-GLOBAL) untuk menggabungkan semua object berdasarkan tableId Form Global (misal satu form ada 2 table, maka di-merge)
    filterTempSubject_Merge: Subject<any> = new Subject<any>();
    filterTempObject_Merge: any = {}; // object -> {}

    arrColor = ["#FF757D", "#52EED2", "#FF6996", "#1AD4D4", "#FF7AB2", "#0FEDFB", "#FF8933", "#3BCAF8", "#FFB74A", "#3AA1FF", "#F8D042", "#3969E4", "#EED496", "#9AAFFB", "#DEB792", "#C4B6ED", "#6AEE8F", "#9A7EEC", "#0AD98E", "#E49FEA"];

    blockChangeAOD: boolean = false;

    otherError = "Can't connect to server. Please Contact Admin ";

    subject_DarkMode = new Subject<any>();
    statusDark: boolean = false;

    langCur: any = "en";
    langArrNum: number = 0;
    //Global change language update list
    langChangeList: any = {
        markAsComplete: [
            { confirmComplete: "Mark as Complete ?", confirmCompleteDescription: "Once you finish this task, you will no longer be able to edit this task anymore !", acceptButton: "Accept", cancelButton: "Cancel" },
            { confirmComplete: "Tandai sebagai selesai ?", confirmCompleteDescription: "Setelah Anda menyelesaikan tugas ini, Anda tidak lagi dapat mengedit tugas ini lagi!", acceptButton: "Terima", cancelButton: "Batal" },
            { confirmComplete: "마크가 완료 되었습니까?", confirmCompleteDescription: "이 작업을 마치면 더 이상이 작업을 더 이상 편집 할 수 없습니다!", acceptButton: "수용하다", cancelButton: "취소" },
            { confirmComplete: "ทำเครื่องหมายว่าสมบูรณ์?", confirmCompleteDescription: "เมื่อคุณทำภารกิจนี้ให้เสร็จคุณจะไม่สามารถแก้ไขงานนี้ได้อีกต่อไป!", acceptButton: "ยอมรับ", cancelButton: "ยกเลิก" },
        ],
        deleteTask: [
            { confirmDelete: "Are you sure ?", finalConfirm: "It will delete Task !", acceptButton: "Yes", cancelButton: "Cancel" },
            { confirmDelete: "Apa kamu yakin ?", finalConfirm: "Ini akan menghapus tugas !", acceptButton: "Ya", cancelButton: "Batal" },
            { confirmDelete: "확실합니까 ?", finalConfirm: "작업이 삭제됩니다!", acceptButton: "예", cancelButton: "취소" },
            { confirmDelete: "คุณแน่ใจไหม ?", finalConfirm: "มันจะลบงาน!", acceptButton: "ใช่", cancelButton: "ยกเลิก" },
        ],
        goalProgressLabel: [
            { development: "Development", performance: "Performance", other: "Other" },
            { development: "Perkembangan", performance: "Kinerja", other: "Lainnya" },
            { development: "개발", performance: "성능", other: "다른" },
            { development: "การพัฒนา", performance: "ประสิทธิภาพ", other: "อื่น" },
        ],
        gender: [
            { Male: "Male", Female: "Female" },
            { Male: "Pria", Female: "Perempuan" },
            { Male: "남성", Female: "여성" },
            { Male: "ชาย", Female: "หญิง" },
        ],
        swal: [
            { chooseAnOption: "Choose an Option ?", correction: "Correction", update: "Update", footerTitle1: "Correction : Overwrite the data. No History will maintain", footerTitle2: "Update : Keeps the history and new change will effect as of effective date" },
            { chooseAnOption: "Pilih opsi?", correction: "Koreksi", update: "Perbarui", footerTitle1: "Koreksi : Menimpa data. Tidak ada Riwayat yang akan dipertahankan", footerTitle2: "Update : Menyimpan riwayat dan perubahan baru akan berlaku sejak tanggal efektif" },
            { chooseAnOption: "옵션을 선택하세요 ?", correction: "보정", update: "업데이트", footerTitle1: "수정 : 데이터를 덮어 씁니다. 어떤 역사도 유지하지 않습니다", footerTitle2: "업데이트 : 역사를 유지하면 새로운 변화가 발효 일 현재 영향을받습니다." },
            { chooseAnOption: "เลือกตัวเลือก?", correction: "การแก้ไข", update: "อัปเดต", footerTitle1: "การแก้ไข: เขียนทับข้อมูล ไม่มีประวัติจะรักษาไว้", footerTitle2: "อัปเดต: รักษาประวัติและการเปลี่ยนแปลงใหม่จะมีผลในวันที่มีผลบังคับใช้" },
        ],
        religion: [
            { Buddhism: "Buddhism", Christianity: "Christianity", Confucianism: "Confucianism", Hinduism: "Hinduism", Islam: "Islam", Other: "Other", "Roman Catholic": "Roman Catholic", Empty: "Empty" },
            { Buddhism: "Buddhisme", Christianity: "Kekristenan", Confucianism: "Konfusianisme", Hinduism: "Hinduisme", Islam: "Islam", Other: "Lainnya", RomanCatholic: "Katolik Roma", Empty: "Kosong" },
            { Buddhism: "불교", Christianity: "기독교", Confucianism: "유도", Hinduism: "힌두교", Islam: "이슬람교", Other: "다른", "Roman Catholic": "로마 카톨릭", Empty: "비어 있는" },
            { Buddhism: "ศาสนาพุทธ", Christianity: "ศาสนาคริสต์", Confucianism: "ลัทธิขงจื้อ", Hinduism: "ศาสนาฮินดู", Islam: "ศาสนาอิสลาม", Other: "อื่น", "Roman Catholic": "โรมันคาทอลิก", Empty: "ว่างเปล่า" },
        ],
        employeeStatus: [
            { "Contract-1": "Contract-1", "Contract-2": "Contract-2", "Contract-3": ":Contract-3", "Contract-4": "Contract-4", "Contract-5": "Contract-5", Permanent: "Permanent", Internship: "Internship", Probation: "Probation" },
            { "Contract-1": "Kontrak-1", "Contract-2": "Kontrak-2", "Contract-3": "Kontrak-3", "Contract-4": "Kontrak-4", "Contract-5": "Kontrak-5", Permanent: "Permanen", Internship: "Magang", Probation: "Masa Percobaan" },
            { "Contract-1": "계약 -1", "Contract-2": "계약 -2", "Contract-3": ":계약 -3", "Contract-4": "계약 -4", "Contract-5": "계약 -5", Permanent: "영구적 인", Internship: "인턴쉽", Probation: "집행 유예" },
            { "Contract-1": "สัญญา -1", "Contract-2": "สัญญา -2", "Contract-3": ":สัญญา -3", "Contract-4": "สัญญา -4", "Contract-5": "สัญญา -5", Permanent: "ถาวร", Internship: "การฝึกงาน", Probation: "การคุมประพฤติ" },
        ],
        applicantSources: [
            { "Career Fairs": "Career Fairs", "Direct Contact": "Direct Contact", Email: "Email", "Company Website": "Company Website" },
            { "Career Fairs": "Bursa kerja", "Direct Contact": "Kontak Langsung", Email: "Surel", "Company Website": "Situs Perusahaan" },
            { "Career Fairs": "커리어 박람회", "Direct Contact": "직접 접촉", Email: "이메일", "Company Website": "회사 웹 사이트" },
            { "Career Fairs": "งานแสดงสินค้า", "Direct Contact": "ติดต่อโดยตรง", Email: "อีเมล", "Company Website": "เว็บไซต์ของ บริษัท" },
        ],
        changeAod: [
            { title: "Are you sure ?", text: "Change As of Date will Reset the Form !", confirmBtn: "Accept", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Mengubah Tanggal akan Mengatur Ulang Formulir !", confirmBtn: "Terima", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "날짜에 따라 변경이 양식을 재설정합니다 !", confirmBtn: "수용하다", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "การเปลี่ยนแปลง ณ วันที่จะรีเซ็ตแบบฟอร์ม !", confirmBtn: "ยอมรับ", cancelBtn: "ยกเลิก" },
        ],
        deleteForm: [
            { title: "Are you sure ?", text: "You won't be able to revert this !", confirmBtn: "Yes, delete it", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Anda tidak akan bisa mengembalikan ini !", confirmBtn: "Ya, hapus", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "당신은 이것을 되돌릴 수 없습니다 !", confirmBtn: "예, 삭제하십시오", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "คุณจะไม่สามารถเปลี่ยนสิ่งนี้ได้ !", confirmBtn: "ใช่ลบออก", cancelBtn: "ยกเลิก" },
        ],
        resetManageEvaluation: [
            { title: "Are you sure ?", text: "You won't be able to revert this !", confirmBtn: "Yes, Confirm", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Anda tidak akan bisa mengembalikan ini !", confirmBtn: "Ya, konfirmasi", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "당신은 이것을 되돌릴 수 없습니다 !", confirmBtn: "예, 확인하십시오", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "คุณจะไม่สามารถเปลี่ยนสิ่งนี้ได้ !", confirmBtn: "ใช่ยืนยัน", cancelBtn: "ยกเลิก" },
        ],
        submitCareerEval: [
            { title: "Are you sure ?", text: "You are about to submit an Evaluation", confirmBtn: "Submit", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Anda akan mengirimkan Evaluasi", confirmBtn: "Kirim", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "평가를 제출하려고합니다", confirmBtn: "제출", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "คุณกำลังจะส่งการประเมินผล", confirmBtn: "ส่ง", cancelBtn: "ยกเลิก" },
        ],
        noDataToDisplay: [{ no_data_to_display: "No data to display" }, { no_data_to_display: "tidak ada data untuk ditampilkan" }, { no_data_to_display: "표시 할 데이터 없음" }, { no_data_to_display: "ไม่มีข้อมูลที่จะแสดง" }],
        delAdditionalBalance: [
            { title: "Are you sure ?", text: "It will delete detail of Additional Balance !", confirmBtn: "Yes", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Ini akan menghapus detail dari Saldo Tambahan !", confirmBtn: "Ya", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "추가 균형의 세부 사항을 삭제합니다 !", confirmBtn: "예", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "มันจะลบรายละเอียดของยอดคงเหลือเพิ่มเติม !", confirmBtn: "ใช่", cancelBtn: "ยกเลิก" },
        ],
        swalEmployeePerformance: [
            { title: "Are you sure ?", textData: "These change will impact to Employee's Performance data !", textRating: "These changes will impact to Employee's Performance Rating !", confirmBtn: "Yes, Confirm", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", textData: "Perubahan ini akan berdampak pada data Kinerja Karyawan !", textRating: "Perubahan ini akan berdampak pada Penilaian Kinerja Karyawan !", confirmBtn: "Ya, konfirmasi", cancelBtn: "Batal" },
            { title: "확실합니까 ?", textData: "이러한 변화는 직원의 성과 데이터에 영향을 미칩니다 !", textRating: "이러한 변경 사항은 직원의 성과 등급에 영향을 미칩니다 !", confirmBtn: "예, 확인하십시오", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", textData: "การเปลี่ยนแปลงเหล่านี้จะส่งผลกระทบต่อข้อมูลประสิทธิภาพของพนักงาน !", textRating: "การเปลี่ยนแปลงเหล่านี้จะส่งผลกระทบต่อการจัดอันดับผลการปฏิบัติงานของพนักงาน !", confirmBtn: "ใช่ยืนยัน", cancelBtn: "ยกเลิก" },
        ],
        swalEmployeeCareerAssessment: [
            { title: "Are you sure ?", textData: "These change will impact to Employee's Career Assessment !", confirmBtn: "Yes, Confirm", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", textData: "Perubahan ini akan berdampak pada Penilaian Karir Karyawan !", confirmBtn: "Ya, konfirmasi", cancelBtn: "Batal" },
            { title: "확실합니까 ?", textData: "이러한 변화는 직원의 경력 평가에 영향을 미칩니다 !", confirmBtn: "예, 확인하십시오", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", textData: "การเปลี่ยนแปลงเหล่านี้จะส่งผลกระทบต่อการประเมินอาชีพของพนักงาน !", confirmBtn: "ใช่ยืนยัน", cancelBtn: "ยกเลิก" },
        ],
        swalEmployeeAssignment: [
            { title: "Are you sure ?", text: "These change will impact to Employee's Assignment data !", confirmBtn: "Yes, Confirm", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Perubahan ini akan berdampak pada data Penugasan Karyawan !", confirmBtn: "Ya, konfirmasi", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "이러한 변경 사항은 직원의 과제 데이터에 영향을 미칩니다 !", confirmBtn: "예, 확인하십시오", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "การเปลี่ยนแปลงเหล่านี้จะส่งผลกระทบต่อข้อมูลการมอบหมายของพนักงาน !", confirmBtn: "ใช่ยืนยัน", cancelBtn: "ยกเลิก" },
        ],
        resetPassword: [
            { title: "Are you sure ?", text: "Password will reset to default password (Firstname and Date of Birth) e.g. : John#01021990", resetBtn: "Yes, Reset", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Kata Sandi akan di atur ulang ke kata sandi bawaaan (Namadepan dan Tanggal Lahir) mis. : John#01021990", resetBtn: "Ya, Atur Ulang", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "비밀번호는 기본 비밀번호 (이름 및 생년월일)로 재설정됩니다. : 요한#01021990", resetBtn: "예, 재설정", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "รหัสผ่านจะรีเซ็ตเป็นรหัสผ่านเริ่มต้น (ชื่อแรกและวันเดือนปีเกิด) เช่น : John#01021990", resetBtn: "ใช่รีเซ็ต", cancelBtn: "ยกเลิก" },
        ],
        confirmProcessDialog: [
            { title: "Are you sure ?", text: "You want to run this Process !", cancelBtn: "Cancel", confirmBtn: "Yes, Confirm" },
            { title: "Apa kamu yakin ?", text: "Anda ingin menjalankan proses ini !", cancelBtn: "Batal", confirmBtn: "Ya, konfirmasi" },
            { title: "확실합니까 ?", text: "이 과정을 실행하고 싶습니다 !", cancelBtn: "취소", confirmBtn: "예, 확인하십시오" },
            { title: "คุณแน่ใจไหม ?", text: "คุณต้องการเรียกใช้กระบวนการนี้ !", cancelBtn: "ยกเลิก", confirmBtn: "ใช่ยืนยัน" },
        ],
        recalculateRequests: [
            { title: "Are you sure ?", text: "All result in this request will be recalculated !", confirmBtn: "Yes, Reprocess", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Semua hasil dalam permintaan ini akan dihitung ulang !", confirmBtn: "Ya, Proses Ulang", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "이 요청의 모든 결과는 다시 계산됩니다 !", confirmBtn: "예, 재 처리", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "ผลลัพธ์ทั้งหมดในคำขอนี้จะถูกคำนวณใหม่ !", confirmBtn: "ใช่ประมวลผลใหม่", cancelBtn: "ยกเลิก" },
        ],
        reprocessedRequests: [
            { title: "Are you sure ?", text: "All result in this request will be reprocessed !", confirmBtn: "Yes, Reprocess", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Semua hasil dalam permintaan ini akan dihitung ulang !", confirmBtn: "Ya, Proses Ulang", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "이 요청의 모든 결과는 다시 계산됩니다 !", confirmBtn: "예, 재 처리", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "ผลลัพธ์ทั้งหมดในคำขอนี้จะถูกคำนวณใหม่ !", confirmBtn: "ใช่ประมวลผลใหม่", cancelBtn: "ยกเลิก" },
        ],
        cancelRequests: [
            { title: "Are you sure ?", text: "All result in this request will be cancel !", confirmBtn: "Yes, Confirm", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Semua hasil dalam permintaan ini akan dibatalkan !", confirmBtn: "Ya, konfirmasi", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "이 요청의 모든 결과는 취소됩니다 !", confirmBtn: "예, 확인하십시오", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "ผลลัพธ์ทั้งหมดในคำขอนี้จะถูกยกเลิก !", confirmBtn: "ใช่ยืนยัน", cancelBtn: "ยกเลิก" },
        ],
        rollbackRequests: [
            { title: "Are you sure ?", text: "All result in this request will be deleted !", confirmBtn: "Yes, Rollback", cancelBtn: "Cancel" },
            { title: "Apa kamu yakin ?", text: "Semua hasil dalam permintaan ini akan dihapus !", confirmBtn: "Ya, Kembalikan", cancelBtn: "Batal" },
            { title: "확실합니까 ?", text: "이 요청의 모든 결과는 삭제됩니다 !", confirmBtn: "예, 롤백", cancelBtn: "취소" },
            { title: "คุณแน่ใจไหม ?", text: "ผลลัพธ์ทั้งหมดในคำขอนี้จะถูกลบ !", confirmBtn: "ใช่ย้อนกลับ", cancelBtn: "ยกเลิก" },
        ],
        runProcessConfirm: [
            { desc: "Are you sure you want to run this process ?", confirmBtn: "Yes", cancelBtn: "No" },
            { desc: "Apakah Anda yakin ingin menjalankan proses ini ?", confirmBtn: "Ya", cancelBtn: "Tidak" },
            { desc: "이 과정을 실행하고 싶습니까 ?", confirmBtn: "예", cancelBtn: "아니" },
            { desc: "แน่ใจหรือว่าต้องการเรียกใช้กระบวนการนี้ ?", confirmBtn: "ใช่", cancelBtn: "ไม่" },
        ],
    };

    errorAuth401Count: number = 0;
    triggerDatatableHeaderTable: Subject<any> = new Subject();
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private location: Location, private http: HttpClient, private router: Router, private translateService: TranslateService, private injector: Injector, private decimalPipe: DecimalPipe, private toastr: ToastrService) {
        this.translateService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            this.langCur = result["lang"];
            this.funcLangChange(result["lang"]);
        });
    }

    token: any;
    access_token: any;
    title: string;

    funcLangChange(lang: String = "en") {
        if (typeof lang != "undefined" && lang != null) {
            this.langCur = lang;

            switch (this.langCur) {
                case "en":
                    this.langArrNum = 0;
                    break;
                case "id":
                    this.langArrNum = 1;
                    break;
                case "kr":
                    this.langArrNum = 2;
                    break;
                case "th":
                    this.langArrNum = 3;
                    break;
            }
        }
    }

    ivSha256Encrypt(text: string) {
        let _key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
        let _iv = CryptoJS.lib.WordArray.random(16);

        let encryptTxt = CryptoJS.AES.encrypt(text, _key, {
            iv: _iv,
            format: CryptoJS.format.Hex,
            mode: CryptoJS.mode.CTR,
            padding: CryptoJS.pad.NoPadding,
        }).toString();
        encryptTxt = _iv + encryptTxt;
        return encryptTxt;
    }

    ivSha256Decrypt(encryptTxt: string) {
        let _key = CryptoJS.enc.Utf8.parse(environment.encryptKey);

        try {
            let decryptTxt = CryptoJS.AES.decrypt(encryptTxt.slice(32, encryptTxt.length).toString(), _key, {
                iv: CryptoJS.enc.Hex.parse(encryptTxt.slice(0, 32)),
                format: CryptoJS.format.Hex,
                mode: CryptoJS.mode.CTR,
                padding: CryptoJS.pad.NoPadding,
            }).toString(CryptoJS.enc.Utf8);
            return decryptTxt;
        } catch (err) {
            return null;
        }
    }

    imageExists(url, callback) {
        var img = new Image();
        img.src = url;
        img.onload = function () {
            callback(true);
        };
        img.onerror = function () {
            callback(false);
        };
    }

    getAvatarBackgroundColor() {
        let avaDec;
        if (localStorage.getItem("GITAV") != null) {
            try {
                avaDec = this.ivSha256Decrypt(localStorage.getItem("GITAV"));
            } catch (err) {
                avaDec = null;
            }
        } else {
            avaDec = null;
        }
        return avaDec;
    }

    locationPath() {
        let urlEndParse: any;

        var url = this.location.prepareExternalUrl(this.location.path());
        var urlEnd = url.lastIndexOf("/") + 1;
        urlEndParse = (url.substr(urlEnd, 1).toUpperCase() + url.slice(urlEnd + 1).toLowerCase()).toUpperCase();
        //remove -
        urlEndParse = urlEndParse.replace(/-/gi, " ");

        //No Get QUery Params
        var urlQuestion = urlEndParse.indexOf("?");
        if (urlQuestion != -1) {
            var urlFinal = urlEndParse.slice(0, urlQuestion);
            urlEndParse = urlFinal;
        }
        return urlEndParse;
    }

    locationMenuPath(hideForm?: boolean) {
        let urlArr = [];
        let urlFind: any;
        let url = this.location.prepareExternalUrl(this.location.path());
        if (url.indexOf("#/") != -1) {
            url = url.substring(url.indexOf("#/") + 2, url.length);
        }
        if (url.indexOf("?") != -1) {
            url = url.substring(0, url.indexOf("?"));
        }
        if (url.trim() == "") {
            urlArr = null;
        } else {
            urlArr = url.split("/");
        }

        if (hideForm && urlArr != null) {
            urlFind = urlArr.findIndex((result) => {
                return result.toLowerCase() == "form";
            });
            if (urlFind != -1) {
                urlArr.splice(urlFind, 1);
            }
        }
        return urlArr;
    }

    emailValid(text) {
        let txt = text;
        let pattern = /[a-zA-Z0-9.\-_+%]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,}/g;
        return txt.match(pattern);
    }

    funcApiTipeModule(tipe_module): any {
        switch (tipe_module) {
            case "lookup":
                return environment.apiDomain_Lookup;
                break;
            default:
                return environment.baseUrl;
                break;
        }
    }

    clean_falsey_urlparam_string(val: any) {
        let value_temp: any = val;
        if (value_temp == undefined) {
            return value_temp;
        }
        // hilangkan query params
        let status_queryParams: boolean = false;
        if (value_temp.indexOf("?") != -1) {
            status_queryParams = true;
            value_temp = value_temp.substring(0, value_temp.indexOf("?"));
        }

        let value_temp_final: any = value_temp.replace(/\/undefined/, "");
        if (status_queryParams) {
            value_temp_final += val.substring(val.indexOf("?"), val.length);
        }

        return value_temp_final;
    }

    getTenantApi(api?, callback?, tipe_module?) {
        let headersTemp: any;
        let readyHit: boolean = false;

        this.loopStorage((res) => {
            if (typeof tipe_module != "undefined" && tipe_module != null && tipe_module == "user/profile") {
                headersTemp = {
                    Authorization: "Bearer " + this.jwt_access_token,
                    "X-TenantID": this.jwt_tenant_id,
                };
                readyHit = true;
            } else {
                // GITAUTHTKN => Authorities Token
                if (typeof localStorage.getItem("GITAUTHTKN") != "undefined" && localStorage.getItem("GITAUTHTKN") != null) {
                    this.jwt_authorities_value = this.ivSha256Decrypt(localStorage.getItem("GITAUTHTKN"));
                }

                let git_role: any = this.ivSha256Decrypt(localStorage.getItem("GITROLE"));

                if (typeof git_role != "undefined" && git_role == "SUPER_ADMIN") {
                    headersTemp = {
                        Authorization: "Bearer " + this.jwt_access_token,
                        "X-TenantID": this.jwt_tenant_id,
                    };
                } else {
                    headersTemp = {
                        Authorization: "Bearer " + this.jwt_access_token,
                        "X-TenantID": this.jwt_tenant_id,
                        AuthorizationToken: this.jwt_authorities_value,
                    };
                }
            }

            this.http
                .get(this.jwt_instance_api + api, {
                    headers: headersTemp,
                })
                .subscribe({
                    next: (result: any) => {
                        // Trigger resize header to make sure datatable body and header sync
                        // when system get api (specifically for datatable component)
                        this.triggerDatatableHeaderTable.next(true);
                        callback(result, "Success");
                    },
                    error: (err: any) => {
                        if (err.status == 400 || err.status == 404 || (err.status.toString().substring(0, 1) == "5" && err.status != 503)) {
                            callback(err, "Error");
                        } else if (err.status == 503) {
                            if (!this.maintenanceMode) {
                                this.maintenanceMode = true;
                                let encryptValue = this.ivSha256Encrypt(JSON.stringify(err));
                                // HAPUS SEMUA KEY GITMAIN atau maintenanceModeMsg dari localstorage sebelum di inject kembali
                                for (let i = localStorage.length - 1; i >= 0; i--) {
                                    if (localStorage.key(i) == "GITMAIN") {
                                        localStorage.removeItem("GITMAIN");
                                    }
                                    if (this.ivSha256Decrypt(localStorage.key(i)) == "maintenanceModeMsg") {
                                        localStorage.removeItem(localStorage.key(i));
                                    }
                                }
                                localStorage.setItem("GITMAIN", encryptValue);
                                // ... end

                                this.router.navigate(["/maintenance"]);
                            }
                        } else if (err.status == 401) {
                            if (this.errorAuth401Count < 4) {
                                this.errorAuth401Count++;
                                this.getTenantRefreshApi("get", this.jwt_access_token, this.jwt_refresh_token, api, {}, callback);
                            } else {
                                this.showToast("You are not authorize to access this page", "error");
                                this.errorAuth401Count = 0;

                                setTimeout(() => {
                                    this.router.navigate(["/home/profile"]);
                                }, 500);
                            }
                        } else {
                            this.showToast("Can't connect to server. Please Contact Admin ", "error");
                            callback(err, "Error Server");
                        }
                    },
                });
        });
    }

    postTenantApi(api?, keyjson?, callback?, timeoutms = 20000) {
        this.loopStorage((res) => {});

        if (typeof localStorage.getItem("GITAUTHTKN") != "undefined" && localStorage.getItem("GITAUTHTKN") != null) {
            this.jwt_authorities_value = this.ivSha256Decrypt(localStorage.getItem("GITAUTHTKN"));
        }

        let headersTemp: any;
        let git_role: any = this.ivSha256Decrypt(localStorage.getItem("GITROLE"));

        if (typeof git_role != "undefined" && git_role == "SUPER_ADMIN") {
            headersTemp = {
                Authorization: "Bearer " + this.jwt_access_token,
                "X-TenantID": this.jwt_tenant_id,
            };
        } else {
            headersTemp = {
                Authorization: "Bearer " + this.jwt_access_token,
                "X-TenantID": this.jwt_tenant_id,
                AuthorizationToken: this.jwt_authorities_value,
            };
        }

        let keyjson_temp: any;
        if (!(keyjson instanceof FormData)) {
            if (typeof keyjson == "object") {
                // INJECTOR utk ambil method dari data typeservices, agar tidak circular dependency injection
                let inj_dataTypeServices = this.injector.get(DataTypeServices);

                keyjson_temp = inj_dataTypeServices.update_to_null_arrobj(JSON.parse(JSON.stringify(keyjson)));
            }
        } else {
            keyjson_temp = keyjson;
        }

        this.http
            .post(this.clean_falsey_urlparam_string(this.jwt_instance_api + api), keyjson_temp, {
                headers: headersTemp,
            })
            .pipe(timeout(timeoutms))
            .subscribe({
                next: (result: any) => {
                    callback(result, "Success");
                },
                error: (err: any) => {
                    if (err.name == "TimeoutError") {
                        this.showToast(err.message, "error");
                        callback(err, "Error");
                        return;
                    }
                    if (err.status == 400 || err.status == 404 || err.status == 405 || (err.status.toString().substring(0, 1) == "5" && err.status != 503)) {
                        callback(err, "Error");
                    } else if (err.status == 503) {
                        if (!this.maintenanceMode) {
                            this.maintenanceMode = true;
                            let encrytMaintance = this.ivSha256Encrypt("maintenanceModeMsg");
                            let encryptValue = this.ivSha256Encrypt(JSON.stringify(err));
                            localStorage.setItem(encrytMaintance, encryptValue);
                            this.router.navigate(["/maintenance"]);
                        }
                    } else if (err.status == 401) {
                        if (this.errorAuth401Count < 4) {
                            this.errorAuth401Count++;
                            this.getTenantRefreshApi("post", this.jwt_access_token, this.jwt_refresh_token, api, keyjson, callback);
                        } else {
                            this.showToast("You are not authorize to access this page", "error");
                            this.errorAuth401Count = 0;

                            setTimeout(() => {
                                this.router.navigate(["/home/profile"]);
                            }, 500);
                        }
                    } else {
                        callback(err, "Error Server");
                        this.showToast("Can't connect to server. Please Contact Admin ", "error");
                    }
                },
            });
    }

    getTenantRefreshApi(tipe?, new_access_token?, new_refresh_token?, api?, keyjson?, callback?) {
        if (new_access_token != null && typeof new_access_token != undefined && new_access_token != "") {
            this.jwt_access_token = new_access_token;
        }
        if (new_refresh_token != null && typeof new_refresh_token != undefined && new_refresh_token != "") {
            this.jwt_refresh_token = new_refresh_token;
        }

        let keyform = new HttpParams({
            fromObject: {
                grant_type: "refresh_token",
                client_id: "git-client",
                refresh_token: this.jwt_refresh_token,
            },
        });

        this.http.post(environment.tokenUrl, keyform).subscribe(
            (result) => {
                this.jwt_access_token = result["access_token"];
                this.jwt_refresh_token = result["refresh_token"];
                let jwt = new JwtHelperService();
                let jwtDecode = jwt.decodeToken(result["access_token"]);
                this.jwt_tenant_id = jwtDecode["tenant_id"][0];

                for (var i = 0; i < localStorage.length; i++) {
                    let key = localStorage.key(i);
                    let decryptKey = this.ivSha256Decrypt(key);
                    let encryptNewKeyjson = this.ivSha256Encrypt(JSON.stringify(result));
                    let encryptNewKey = this.ivSha256Encrypt("keyjson");
                    localStorage.removeItem("GITFNM");
                    if (decryptKey == "keyjson") {
                        localStorage.removeItem(key);
                        localStorage.setItem(encryptNewKey, encryptNewKeyjson);
                        break;
                    }
                }
                if (tipe == "get") {
                    this.getTenantApi(api, callback);
                } else if (tipe == "post") {
                    this.postTenantApi(api, keyjson, callback);
                } else {
                    callback();
                }
            },
            (err) => {
                localStorage.clear();
                this.router.navigate(["login"]);
            }
        );
    }

    loopStorage(callback) {
        let existsToken: boolean = false;
        const helper = new JwtHelperService();

        if (typeof this.jwt_access_token == undefined || this.jwt_access_token == null || this.jwt_access_token == "") {
            if (localStorage.length > 0) {
                for (var i = 0; i < localStorage.length; i++) {
                    try {
                        if (localStorage.key(i) != "GITAOD") {
                            if (typeof localStorage.getItem("GITAUTHTKN") != "undefined" && localStorage.getItem("GITAUTHTKN") != null) {
                                this.jwt_authorities_value = this.ivSha256Decrypt(localStorage.getItem("GITAUTHTKN"));
                            }

                            if (typeof localStorage.getItem("GITINSTAPI") != "undefined" && localStorage.getItem("GITINSTAPI") != null) {
                                this.jwt_instance_api = this.ivSha256Decrypt(localStorage.getItem("GITINSTAPI"));
                            }

                            if (this.ivSha256Decrypt(localStorage.key(i)) != "") {
                                // return
                                let keyStor = this.ivSha256Decrypt(localStorage.key(i));
                                let valStor = this.ivSha256Decrypt(localStorage.getItem(localStorage.key(i)));

                                if (keyStor == "keyjson") {
                                    this.jwt_keyjson = valStor;
                                    let valStorObj = JSON.parse(valStor);
                                    this.jwt_access_token = typeof valStorObj["access_token"] != "undefined" ? valStorObj["access_token"] : null;
                                    this.jwt_refresh_token = typeof valStorObj["refresh_token"] != "undefined" ? valStorObj["refresh_token"] : null;

                                    let jwt = new JwtHelperService();
                                    let jwtDecode = jwt.decodeToken(this.jwt_access_token);
                                    this.jwt_instance_api = jwtDecode["instance_api"][0];
                                    this.jwt_tenant_id = jwtDecode["tenant_id"][0];
                                    existsToken = true;
                                }
                                if (keyStor == "authorities") {
                                    this.jwt_authorities_key = localStorage.key(i);
                                    this.jwt_authorities = JSON.parse(valStor);
                                }

                                if (keyStor == "keyjson") {
                                    let accessTokenDecode = helper.decodeToken(JSON.parse(this.ivSha256Decrypt(localStorage.getItem(localStorage.key(i)))).access_token);
                                    if (typeof accessTokenDecode["resource_access"]["git-client"] != "undefined") {
                                        let getCheckData_SUPER_Admin = accessTokenDecode["resource_access"]["git-client"]["roles"].findIndex((result) => result == "SUPER_ADMIN");
                                        let getCheckData_SC_Admin = accessTokenDecode["resource_access"]["git-client"]["roles"].findIndex((result) => result == "SC_ADMIN");
                                        if (getCheckData_SUPER_Admin != -1 && getCheckData_SC_Admin == -1) {
                                            this.jwt_role_user = accessTokenDecode["resource_access"]["git-client"]["roles"][getCheckData_SUPER_Admin];
                                        } else if (getCheckData_SC_Admin != -1 && getCheckData_SUPER_Admin == -1) {
                                            this.jwt_role_user = accessTokenDecode["resource_access"]["git-client"]["roles"][getCheckData_SC_Admin];
                                        } else {
                                            this.jwt_role_user = "STAFF";
                                        }
                                    } else {
                                        this.jwt_role_user = "STAFF";
                                    }

                                    // SET GITROLE to localStorage
                                    if (typeof localStorage.getItem("GITROLE") != "undefined" && localStorage.getItem("GITROLE") != null) {
                                        localStorage.removeItem("GITROLE");
                                    }
                                    localStorage.setItem("GITROLE", this.ivSha256Encrypt(this.jwt_role_user));
                                }
                            }
                        }
                    } catch (err) {}
                }
            }
        } else {
            existsToken = true;
        }
        callback(existsToken);
    }

    getInformationEmployee(callback?) {
        let tempInformationEmployee: any = {};
        this.getTenantApi(
            "/api/user/profile",
            (result, status) => {
                if (status == "Error") {
                    // sebagai tanda bahwa user/profile sudah di hit api nya
                    subject_sidebar_userprofile_status.next(true);

                    if (localStorage.getItem("GITFNM") != "undefined") {
                        localStorage.removeItem("GITFNM");
                    }
                    if (localStorage.getItem("GITEID") != "undefined") {
                        localStorage.removeItem("GITEID");
                    }
                    if (localStorage.getItem("GITEPD") != "undefined") {
                        localStorage.removeItem("GITEPD");
                    }
                    let inj_dataTypeServices = this.injector.get(DataTypeServices);
                    this.showToast(inj_dataTypeServices.getErrorMessageApi(result), "error");
                    return;
                } else {
                    tempInformationEmployee["authoritiesToken"] = result["authoritiesToken"];

                    if (tempInformationEmployee["authoritiesToken"] != null && tempInformationEmployee["authoritiesToken"] != "" && typeof tempInformationEmployee["authoritiesToken"] != "undefined") {
                        if (typeof localStorage.getItem("GITAUTHTKN") != "undefined" && localStorage.getItem("GITAUTHTKN") != null) {
                            // AUTHORITIES TOKEN
                            localStorage.removeItem("GITAUTHTKN");
                        }
                        localStorage.setItem("GITAUTHTKN", this.ivSha256Encrypt(tempInformationEmployee["authoritiesToken"]));

                        // sebagai tanda bahwa user/profile sudah di hit api nya
                        subject_sidebar_userprofile_status.next(true);
                        // ... end

                        let jwtAuthoritiesToken = new JwtHelperService();
                        let jwtAuthoritiesDecodeToken = jwtAuthoritiesToken.decodeToken(tempInformationEmployee["authoritiesToken"]);
                        let arrAuthoritiesToken = jwtAuthoritiesDecodeToken["authorities"];

                        let encodeAuthorities = this.ivSha256Encrypt("authorities");

                        if (localStorage.length > 0) {
                            for (var i = localStorage.length - 1; i >= 0; i--) {
                                let keyTemp = this.ivSha256Decrypt(localStorage.key(i));

                                if (keyTemp != "" && keyTemp != null && keyTemp == "authorities") {
                                    localStorage.removeItem(localStorage.key(i));
                                }
                            }
                        }

                        if (this.jwt_authorities == "" || typeof this.jwt_authorities == "undefined" || this.jwt_authorities == null) {
                            localStorage.setItem(encodeAuthorities, this.ivSha256Encrypt(JSON.stringify(arrAuthoritiesToken)));
                        } else {
                            localStorage.removeItem(this.jwt_authorities_key);
                            localStorage.setItem(encodeAuthorities, this.ivSha256Encrypt(JSON.stringify(arrAuthoritiesToken)));
                        }

                        this.jwt_authorities_key = encodeAuthorities;
                        this.jwt_authorities = arrAuthoritiesToken;
                    }

                    tempInformationEmployee["employeeName"] = result["fullName"];
                    tempInformationEmployee["employeeId"] = result["employeeId"];
                    let getInitialChar = tempInformationEmployee["employeeName"].toUpperCase().split(" ");

                    if (localStorage.getItem("GITFNM") != "undefined") {
                        localStorage.removeItem("GITFNM");
                    }
                    if (localStorage.getItem("GITEID") != "undefined") {
                        //EMPLOYEE ID
                        localStorage.removeItem("GITEID");
                    }
                    if (localStorage.getItem("GITEPD") != "undefined") {
                        //EMPLOYMENT ID
                        localStorage.removeItem("GITEPD");
                    }
                    localStorage.setItem("GITFNM", this.ivSha256Encrypt(tempInformationEmployee["employeeName"] != null ? tempInformationEmployee["employeeName"] : ""));
                    localStorage.setItem("GITEID", this.ivSha256Encrypt(tempInformationEmployee["employeeId"] != null ? tempInformationEmployee["employeeId"] : ""));
                    localStorage.setItem("GITEPD", this.ivSha256Encrypt(result["employmentId"] != null ? result["employmentId"] : ""));

                    if (getInitialChar[1].charAt(0).match(/[A-Z]/)) {
                        tempInformationEmployee["employeeInitial"] = getInitialChar[0].charAt(0) + "" + getInitialChar[1].charAt(0);
                    } else {
                        tempInformationEmployee["employeeInitial"] = getInitialChar[0].charAt(0);
                    }
                    tempInformationEmployee["employeeInitial"] = result["alias"];

                    this.companyId = result["companyId"];
                    let pict = result["profilePicture"];
                    this.imageExists(pict, (result) => {
                        if (result) {
                            tempInformationEmployee["employeeImage"] = pict;
                            tempInformationEmployee["backgroundAvatar"] = "grey";
                        } else {
                            tempInformationEmployee["employeeImage"] = null;
                            tempInformationEmployee["backgroundAvatar"] = this.getAvatarBackgroundColor();
                        }
                        this.employeeProfilePicture.next(
                            JSON.stringify({
                                employeeImage: tempInformationEmployee["employeeImage"],
                                backgroundAvatar: tempInformationEmployee["backgroundAvatar"],
                            })
                        );
                    });

                    this.employeInformation.next(JSON.stringify(tempInformationEmployee));
                }
                if (typeof callback != "undefined") callback();
            },
            "user/profile"
        );
    }
    getCompanyProfile(callback?) {
        this.getTenantApi(
            "/api/user/profile/my-company-logo",
            (result: any, status: any) => {
                if (status == "Error") {
                    let inj_dataTypeServices = this.injector.get(DataTypeServices);
                    this.showToast(inj_dataTypeServices.getErrorMessageApi(result), "error");
                    return;
                } else {
                    let pictCompany = result["companyLogoUrl"];
                    let isImageExists = false;
                    if (pictCompany != null) {
                        if (pictCompany.indexOf("https://") != -1) {
                            this.imageExists(pictCompany, (result) => {
                                isImageExists = result;
                                this.imageLoad = false;
                                if (isImageExists) {
                                    this.companyLogo = pictCompany;
                                } else {
                                    this.companyLogo = this.statusDark ? "assets/img/brand/starconnect-06-darkmode.png" : "assets/img/brand/starconnect-06.png";
                                }
                                this.imageLoad = true;
                            });
                        } else {
                            this.imageExists("https://" + pictCompany, (result) => {
                                isImageExists = result;
                                this.imageLoad = false;
                                if (isImageExists) {
                                    this.companyLogo = "https://" + pictCompany;
                                } else {
                                    this.companyLogo = this.statusDark ? "assets/img/brand/starconnect-06-darkmode.png" : "assets/img/brand/starconnect-06.png";
                                }
                                this.imageLoad = true;
                            });
                        }
                    } else {
                        this.imageLoad = false;
                        this.companyLogo = this.statusDark ? "assets/img/brand/starconnect-06-darkmode.png" : "assets/img/brand/starconnect-06.png";
                        this.imageLoad = true;
                    }
                }
                if (typeof callback != "undefined") callback();
            },
            "user/profile"
        );
    }

    showToast(msg: any, type: "success" | "error" | "warning") {
        switch (type) {
            case "success":
                this.toastr.show(
                    '<div class="row">' +
                        ' <div class="alert-text col-1 d-flex justify-content-center align-items-center">' +
                        ' <span class="alert-icon ni ni-bell-55 d-block mr-0" data-notify="icon"></span>' +
                        " </div>" +
                        ' <div class="col-10">' +
                        ' <span class="alert-title" data-notify="title">Success</span>' +
                        ' <span class="toast-message" data-notify="message">' +
                        msg +
                        "</span>" +
                        " </div>" +
                        " </div>",
                    "",
                    {
                        timeOut: 1500,
                        closeButton: true,
                        enableHtml: true,
                        tapToDismiss: false,
                        titleClass: "alert-title",
                        messageClass: "w-100",
                        progressBar: true,
                        positionClass: "toast-top-center",
                        toastClass: "ngx-toastr alert alert-dismissible alert-success alert-notify",
                    }
                );
                break;
            case "error":
                this.toastr.show(
                    '<div class="row">' +
                        ' <div class="alert-text col-1 d-flex justify-content-center align-items-center">' +
                        ' <span class="alert-icon ni ni-bell-55 d-block mr-0" data-notify="icon"></span>' +
                        " </div>" +
                        ' <div class="col-10">' +
                        ' <span class="alert-title" data-notify="title">Error</span>' +
                        ' <span class="toast-message" data-notify="message">' +
                        msg +
                        "</span>" +
                        "</div>" +
                        "</div>",
                    "",
                    {
                        timeOut: 3000,
                        closeButton: true,
                        enableHtml: true,
                        tapToDismiss: false,
                        titleClass: "alert-title",
                        messageClass: "w-100",
                        progressBar: true,
                        positionClass: "toast-top-center",
                        toastClass: "ngx-toastr alert alert-dismissible alert-danger alert-notify",
                    }
                );
                break;
            case "warning":
                this.toastr.show(
                    '<div class="row">' +
                        ' <div class="alert-text col-1 d-flex justify-content-center align-items-center">' +
                        ' <span class="alert-icon ni ni-bell-55 d-block mr-0" data-notify="icon"></span>' +
                        " </div>" +
                        ' <div class="col-10">' +
                        ' <span class="alert-title" data-notify="title">Warning</span>' +
                        ' <span class="toast-message" data-notify="message">' +
                        msg +
                        "</span>" +
                        "</div>" +
                        "</div>",
                    "",
                    {
                        timeOut: 3000,
                        closeButton: true,
                        enableHtml: true,
                        tapToDismiss: false,
                        titleClass: "alert-title",
                        messageClass: "w-100",
                        progressBar: true,
                        positionClass: "toast-top-center",
                        toastClass: "ngx-toastr alert alert-dismissible alert-warning alert-notify",
                    }
                );
                break;
        }
    }

    formatDate(tanggal, formatTgl, basedOnTimezone = false) {
        let aodTop: any;
        let monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let dayLib = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        //change date format to default of Asia/Jakarta except for menu using timezone
        if (!basedOnTimezone) {
            tanggal = new Date(new Date(tanggal).toLocaleString("en-US", { timeZone: "Asia/Jakarta" }));
        }

        try {
            switch (formatTgl.toString().toUpperCase()) {
                case "DD-MM-YYYY":
                    aodTop = ("0" + tanggal.getDate()).slice(-2) + "-" + ("0" + (tanggal.getMonth() + 1)).slice(-2) + "-" + tanggal.getFullYear().toString().replace("-", "");
                    break;
                case "YYYY-MM-DD":
                    aodTop = tanggal.getFullYear().toString().replace("-", "") + "-" + ("0" + (tanggal.getMonth() + 1)).slice(-2) + "-" + ("0" + tanggal.getDate()).slice(-2);
                    break;
                case "YYYY-MM-DD HH:MI":
                    aodTop = tanggal.getFullYear().toString().replace("-", "") + "-" + ("0" + (tanggal.getMonth() + 1)).slice(-2) + "-" + ("0" + tanggal.getDate()).slice(-2) + " " + ("0" + tanggal.getHours()).slice(-2) + ":" + ("0" + tanggal.getMinutes()).slice(-2);
                    break;
                case "YYYY-MM-DDTHH:MI":
                    aodTop = tanggal.getFullYear().toString().replace("-", "") + "-" + ("0" + (tanggal.getMonth() + 1)).slice(-2) + "-" + ("0" + tanggal.getDate()).slice(-2) + "T" + ("0" + tanggal.getHours()).slice(-2) + ":" + ("0" + tanggal.getMinutes()).slice(-2);
                    break;
                case "YYYYMMDD":
                    aodTop = tanggal.getFullYear().toString().replace("-", "") + ("0" + (tanggal.getMonth() + 1)).slice(-2) + ("0" + tanggal.getDate()).slice(-2);
                    break;
                case "YYYYMMDDHHMM":
                    aodTop = tanggal.getFullYear().toString().replace("-", "") + ("0" + (tanggal.getMonth() + 1)).slice(-2) + ("0" + tanggal.getDate()).slice(-2) + "" + ("0" + tanggal.getHours()).slice(-2) + "" + ("0" + tanggal.getMinutes()).slice(-2);
                    break;
                case "DD MMM YYYY":
                    aodTop = ("0" + tanggal.getDate()).slice(-2) + " " + monthName[tanggal.getMonth()].substring(0, 3) + " " + tanggal.getFullYear().toString().replace("-", "");
                    break;
                case "DD-MMM-YYYY":
                    aodTop = ("0" + tanggal.getDate()).slice(-2) + "-" + monthName[tanggal.getMonth()].substring(0, 3) + "-" + tanggal.getFullYear().toString().replace("-", "");
                    break;
                case "DD/MM/YYYY":
                    aodTop = ("0" + tanggal.getDate()).slice(-2) + "/" + ("0" + (tanggal.getMonth() + 1)).slice(-2) + "/" + tanggal.getFullYear().toString().replace("-", "");
                    break;
                case "MMM-YYYY":
                    aodTop = monthName[tanggal.getMonth()].substring(0, 3) + "-" + tanggal.getFullYear().toString().replace("-", "");
                    break;
                case "MM-YYYY":
                    aodTop = ("0" + (tanggal.getMonth() + 1)).slice(-2) + "-" + tanggal.getFullYear().toString().replace("-", "");
                    break;
                case "MMMM":
                    aodTop = monthName[tanggal.getMonth()];
                    break;
                case "MMMM YYYY":
                    aodTop = monthName[tanggal.getMonth()] + " " + tanggal.getFullYear().toString().replace("-", "");
                    break;
                case "DD MMMM YYYY":
                    aodTop = ("0" + tanggal.getDate()).slice(-2) + " " + monthName[tanggal.getMonth()] + " " + tanggal.getFullYear().toString().replace("-", "");
                    break;
                case "YYYY-MM":
                    aodTop = tanggal.getFullYear().toString().replace("-", "") + "-" + ("0" + (tanggal.getMonth() + 1)).slice(-2);
                    break;
                case "DD-MMM-YYYY HH:MI:SS":
                    if (typeof tanggal == "string") {
                        tanggal = new Date(tanggal);
                    }
                    aodTop =
                        ("0" + tanggal.getDate()).slice(-2) +
                        "-" +
                        monthName[tanggal.getMonth()].substring(0, 3) +
                        "-" +
                        tanggal.getFullYear().toString().replace("-", "") +
                        " " +
                        ("0" + tanggal.getHours()).slice(-2) +
                        ":" +
                        ("0" + tanggal.getMinutes()).slice(-2) +
                        ":" +
                        ("0" + tanggal.getSeconds()).slice(-2);
                    break;
                case "YYYY-MM-DD HH:MI:SS":
                    if (typeof tanggal == "string") {
                        tanggal = new Date(tanggal);
                    }
                    aodTop =
                        tanggal.getFullYear().toString().replace("-", "") +
                        "-" +
                        ("0" + (tanggal.getMonth() + 1)).slice(-2) +
                        "-" +
                        ("0" + tanggal.getDate()).slice(-2) +
                        " " +
                        ("0" + tanggal.getHours()).slice(-2) +
                        ":" +
                        ("0" + tanggal.getMinutes()).slice(-2) +
                        ":" +
                        ("0" + tanggal.getSeconds()).slice(-2);
                    break;
                case "DD MMM YYYY HH:MI":
                    if (typeof tanggal == "string") {
                        tanggal = new Date(tanggal);
                    }
                    aodTop = ("0" + tanggal.getDate()).slice(-2) + " " + monthName[tanggal.getMonth()].substring(0, 3) + " " + tanggal.getFullYear().toString().replace("-", "") + " " + ("0" + tanggal.getHours()).slice(-2) + ":" + ("0" + tanggal.getMinutes()).slice(-2);
                    break;
                case "DD MMM YYYY, HH:MI":
                    if (typeof tanggal == "string") {
                        tanggal = new Date(tanggal);
                    }
                    aodTop = ("0" + tanggal.getDate()).slice(-2) + " " + monthName[tanggal.getMonth()].substring(0, 3) + " " + tanggal.getFullYear().toString().replace("-", "") + ", " + ("0" + tanggal.getHours()).slice(-2) + ":" + ("0" + tanggal.getMinutes()).slice(-2);
                    break;
                case "DD-MMM-YYYY HH:MI":
                    if (typeof tanggal == "string") {
                        tanggal = new Date(tanggal);
                    }
                    aodTop = ("0" + tanggal.getDate()).slice(-2) + "-" + monthName[tanggal.getMonth()].substring(0, 3) + "-" + tanggal.getFullYear().toString().replace("-", "") + " " + ("0" + tanggal.getHours()).slice(-2) + ":" + ("0" + tanggal.getMinutes()).slice(-2);
                    break;
                case "HH:MM":
                    aodTop = ("0" + tanggal.getHours()).slice(-2) + ":" + ("0" + tanggal.getMinutes()).slice(-2);
                    break;
                case "HH:MM:SS":
                    aodTop = ("0" + tanggal.getHours()).slice(-2) + ":" + ("0" + tanggal.getMinutes()).slice(-2) + ":" + ("0" + tanggal.getSeconds()).slice(-2);
                    break;
                case "DDD, DD MMMM YYYY": // Friday, 14 May 2021
                    if (typeof tanggal == "string") {
                        tanggal = new Date(tanggal);
                    }
                    aodTop = dayLib[tanggal.getDay()] + ", " + ("0" + tanggal.getDate()).slice(-2) + " " + monthName[tanggal.getMonth()] + " " + tanggal.getFullYear().toString().replace("-", "");
                    break;
                case "MMMM DD YYYY":
                    if (typeof tanggal == "string") {
                        tanggal = new Date(tanggal);
                    }
                    aodTop = monthName[tanggal.getMonth()] + " " + ("0" + tanggal.getDate()).slice(-2) + " " + tanggal.getFullYear().toString().replace("-", "");
                    break;
                case "DDD, DD-MMM-YYYY": // Friday, 14 May 2021
                    if (typeof tanggal == "string") {
                        tanggal = new Date(tanggal);
                    }
                    aodTop = dayLib[tanggal.getDay()] + ", " + ("0" + tanggal.getDate()).slice(-2) + "-" + monthName[tanggal.getMonth()].substring(0, 3) + "-" + tanggal.getFullYear().toString().replace("-", "");
                    break;
                default:
                    this.showToast('(FOR TECHNICAL) Format Date "' + formatTgl + '" is not exists in formatDate function !', "warning");
                    break;
            }
        } catch (e) {}

        return aodTop;
    }

    formatNumber(val: number, tipe: "decimal" | "decimal_2" | "currency"): any {
        let val_convert: any;
        if (tipe == "decimal") {
            val_convert = Number(val).toLocaleString("id-ID", { style: "decimal" });
        } else if (tipe == "decimal_2") {
            val_convert = Number(val).toLocaleString("id-ID", { style: "decimal", minimumFractionDigits: 2 });
        } else if (tipe == "currency") {
            val_convert = Number(val).toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                maximumFractionDigits: 0,
            });
        }
        return val_convert;
    }

    setAODToLocalStorage(aod: any) {
        if (typeof aod == "string") {
            aod = new Date(aod);
        }

        let aodEnc = this.ivSha256Encrypt(this.formatDate(aod, "yyyy-MM-dd"));
        if (localStorage.getItem("GITAOD") == null) {
            localStorage.removeItem("GITAOD");
        }
        localStorage.setItem("GITAOD", aodEnc);
    }

    getAODToLocalStorage() {
        let aodDec;
        if (localStorage.getItem("GITAOD") != null) {
            try {
                aodDec = this.ivSha256Decrypt(localStorage.getItem("GITAOD"));
            } catch (err) {
                aodDec = null;
            }
        } else {
            aodDec = null;
        }
        return aodDec;
    }

    roundingNumber(number: number): string {
        // only cover up to 1Billion
        if (number >= 1000 && number <= 999999) {
            return this.decimalPipe.transform(number / 1000, "1.1-1") + "K";
        } else if (number >= 1000000 && number <= 999999999) {
            return this.decimalPipe.transform(number / 1000000, "1.1-1") + "M";
        } else if (number >= 1000000000 && number <= 999999999999) {
            return this.decimalPipe.transform(number / 1000000000, "1.1-1") + "B";
        } else {
            return number.toString();
        }
    }

    getMonthString(codeMonth: number, typeMonth: "full" | "half") {
        let monthStr: string;
        let monthLib = [];
        monthLib = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        if (typeMonth == "full") {
            return monthLib[codeMonth];
        } else {
            return monthLib[codeMonth].toString().slice(0, 3);
        }
    }

    workingHoursText(attr: string, category?: "hours" | "minutes") {
        let attrTemp: any;
        let hours: any;
        let minutes: any;
        let textTime: any = "";

        attr = attr.replace(/\s/gi, "");

        attrTemp = attr.split(":");
        if (attrTemp.length >= 2) {
            hours = parseInt(attrTemp[0]);
            minutes = parseInt(attrTemp[1]);
            if (hours > 0) {
                textTime = hours + " Hours";
                if (category == "hours" && typeof category != "undefined") {
                    return textTime;
                }
            }
            if (minutes > 0) {
                textTime += " " + minutes + " Minutes";
                if (category == "minutes" && typeof category != "undefined") {
                    return minutes + " Minutes";
                }
            }
            textTime = textTime.trim();

            if (typeof category != "undefined") {
                return "";
            }
        } else {
            textTime = "";
        }

        return textTime;
    }

    getRandomColor() {
        let colorsBackground = this.arrColor;
        let randomColorAvatar = colorsBackground[Math.floor(Math.random() * colorsBackground.length)];
        return randomColorAvatar;
    }

    hideSortFilterTable(idx?: any, tableId?: any) {
        let strJoin: any = "";
        setTimeout(() => {
            strJoin = typeof tableId == undefined || tableId == null || tableId == "" ? ".reportfilterbackground" : "." + tableId + " .reportfilterbackground";

            // const varSortBtn = document.querySelectorAll(".reportfilterbackground")

            const varSortBtn = document.querySelectorAll(strJoin);
            if (idx != null && typeof idx != "undefined") {
                if (document.body.contains(varSortBtn[idx].nextSibling.nextSibling.nextSibling)) {
                    varSortBtn[idx].nextSibling.nextSibling.nextSibling.remove();
                }
            } else {
                for (var i = 0; i < varSortBtn.length; i++) {
                    if (document.body.contains(varSortBtn[i].nextSibling.nextSibling.nextSibling)) {
                        varSortBtn[i].nextSibling.nextSibling.nextSibling.remove();
                    }
                }
            }
        });
    }

    // SHOW & HIDE FOR BS-DATEPICKER WITH LANGUAGE
    interMonthGrid: any;
    indicatorSwitchMonth: boolean = false;

    onHiddenPicker($event) {
        document.body.classList.remove("bs-datepicker-months-grid-default");
        clearInterval(this.interMonthGrid);
    }

    onShownPicker($event) {
        // *Beberapa styles bs-datepicker ditambahkan ke styles.scss

        document.body.classList.add("bs-datepicker-months-grid-default");

        $event.navigateTo = (evt: any): void => {
            $event._store.dispatch($event._actions.navigateStep(evt.step));
            this.funcChangeDatePicker_Lang(evt.step.month);
            this.funcMonthGrid_Lang();
        };

        // CEK TERUS MENERUS UTK MENG-ANTISIPASI MASUK KE TABLE MONTHS OR YEARS
        this.interMonthGrid = setInterval(() => {
            const yearGrid = document.querySelectorAll(".bs-datepicker-body table.years td");
            const monthGrid = document.querySelectorAll(".bs-datepicker-body table.months td");

            if (monthGrid.length > 0 || yearGrid.length > 0) {
                this.funcMonthGrid_Lang();
                this.indicatorSwitchMonth = true;
                this.funcDaySwitch();
            }
        });
        this.funcChangeDatePicker_Lang(0);
    }

    funcDaySwitch() {
        // POSISI SEDANG DI MONTH, SIAP-SIAP UNTUK PINDAH BALIK KE DAY
        if (this.indicatorSwitchMonth) {
            const intDaySwitch = setInterval(() => {
                const daySwitch = document.querySelectorAll(".bs-datepicker-container div[ng-reflect-ng-switch='day']");
                if (daySwitch.length > 0) {
                    this.indicatorSwitchMonth = false;
                    this.funcChangeDatePicker_Lang(0);
                    clearInterval(intDaySwitch);
                }
            });
        }
    }

    funcChangeDatePicker_Lang(changeMonth?: any) {
        let datepicker_header = document.querySelectorAll(".bs-datepicker-head button.current span");
        let b = (<HTMLElement>datepicker_header[0]).innerText;

        let monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let monthNameIndonesia = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        let monthNameKorea = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
        let monthNameThailand = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];

        let decLang: any = this.ivSha256Decrypt(localStorage.getItem("GITLANG"));

        // kondisi pertama cek dengan inggris terlebih dahulu
        let findMonth = monthName.findIndex((res) => res == b.toString());

        if (findMonth == -1) {
            switch (decLang) {
                case "id":
                    findMonth = monthNameIndonesia.findIndex((res) => res == b.toString());
                    break;
                case "kr":
                    findMonth = monthNameKorea.findIndex((res) => res == b.toString());
                    break;
                case "th":
                    findMonth = monthNameThailand.findIndex((res) => res == b.toString());
                    break;
                case "en":
                default:
                    findMonth = monthName.findIndex((res) => res == b.toString());
                    break;
            }
        }

        if (findMonth >= 0) {
            let idx: any = findMonth + changeMonth; // changeMonth -> -1 or 1
            if (idx == -1) {
                idx = 11; //jika posisi january ke december (tahun lalu), maka di hardcode ke 11
            } else if (idx == 12) {
                idx = 0;
            }

            let monthFinal: any; // real month after calculation
            switch (decLang) {
                case "id":
                    monthFinal = monthNameIndonesia[idx];
                    break;
                case "kr":
                    monthFinal = monthNameKorea[idx];
                    break;
                case "th":
                    monthFinal = monthNameThailand[idx];
                    break;
                case "en":
                default:
                    monthFinal = monthName[idx];
                    break;
            }

            setTimeout(() => {
                // (MONTH) REPLACE DENGAN LABEL MONTH SESUAI LANGUAGE TERPILIH
                document.querySelectorAll(".bs-datepicker-head button.current")[0].innerHTML = "<span class = 'custom-label-monthyear'>" + monthFinal + "</span>";

                // (YEAR) REPLACE KEMBALI YEAR
                let yearElement = document.querySelectorAll(".bs-datepicker-head button.current")[1].children[0];
                let yearText = (<HTMLElement>yearElement).innerText;

                document.querySelectorAll(".bs-datepicker-head button.current")[1].innerHTML = "<span class = 'custom-label-monthyear'>" + yearText + "</span>";

                // (DAY)
                let daySunConvert: any;
                let dayMonConvert: any;
                let dayTueConvert: any;
                let dayWedConvert: any;
                let dayThuConvert: any;
                let dayFriConvert: any;
                let daySatConvert: any;
                switch (decLang) {
                    case "id":
                        daySunConvert = "M";
                        dayMonConvert = "Sn";
                        dayTueConvert = "Se";
                        dayWedConvert = "R";
                        dayThuConvert = "K";
                        dayFriConvert = "J";
                        daySatConvert = "Sb";
                        break;
                    case "kr":
                        daySunConvert = "일";
                        dayMonConvert = "월";
                        dayTueConvert = "화";
                        dayWedConvert = "수";
                        dayThuConvert = "목";
                        dayFriConvert = "금";
                        daySatConvert = "토";
                        break;
                    case "th":
                        daySunConvert = "อาทิตย์";
                        dayMonConvert = "จันทร์";
                        dayTueConvert = "อังคาร";
                        dayWedConvert = "พุธ";
                        dayThuConvert = "พฤหัสบดี";
                        dayFriConvert = "ศุกร์";
                        daySatConvert = "เสาร์";
                        break;
                    case "en":
                    default:
                        daySunConvert = "Sun";
                        dayMonConvert = "Mon";
                        dayTueConvert = "Tue";
                        dayWedConvert = "Wed";
                        dayThuConvert = "Thu";
                        dayFriConvert = "Fri";
                        daySatConvert = "Sat";
                        break;
                }
                const daysWeek = document.querySelectorAll(".bs-datepicker-body table.days.weeks thead tr th");
                daysWeek.forEach((ele, idx) => {
                    // #9aaec1 warna default
                    (<HTMLElement>ele).classList.add("custom-label-day");

                    switch (idx) {
                        case 1:
                            (<HTMLElement>ele).innerText = daySunConvert;
                            break; //sunday
                        case 2:
                            (<HTMLElement>ele).innerText = dayMonConvert;
                            break; //monday
                        case 3:
                            (<HTMLElement>ele).innerText = dayTueConvert;
                            break;
                        case 4:
                            (<HTMLElement>ele).innerText = dayWedConvert;
                            break;
                        case 5:
                            (<HTMLElement>ele).innerText = dayThuConvert;
                            break;
                        case 6:
                            (<HTMLElement>ele).innerText = dayFriConvert;
                            break;
                        case 7:
                            (<HTMLElement>ele).innerText = daySatConvert;
                            break;
                    }
                });
                // (TODAY) BUTTON
                let todayLabel: any;
                switch (decLang) {
                    case "id":
                        todayLabel = "Hari Ini";
                        break;
                    case "kr":
                        todayLabel = "오늘";
                        break;
                    case "th":
                        todayLabel = "วันนี้";
                        break;
                    case "en":
                    default:
                        todayLabel = "Today";
                        break;
                }
                const todayButton = document.querySelectorAll(".bs-datepicker-container .bs-datepicker-buttons .btn-today-wrapper .btn");
                todayButton.forEach((ele, idx) => {
                    (<HTMLElement>ele).innerText = todayLabel;
                });
            });
            // nantinya jika semua datepicker sudah di update dgn ditambahkan onShownPicker, maka aktifkan css  "bs-datepicker (language)"
            // pada styles.scss
            // fungsinya untuk membuat label nama bulan default (english) ke transparent, jadi tidak terlihat flicker.
        }
    }

    funcMonthGrid_Lang() {
        // (MONTH IN GRID)
        let monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let monthNameIndonesia = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        let monthNameKorea = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
        let monthNameThailand = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];

        let decLang: any = this.ivSha256Decrypt(localStorage.getItem("GITLANG"));
        let decLang_Selected: any[] = [];
        switch (decLang) {
            case "en":
            default:
                decLang_Selected = monthName;
                break;
            case "id":
                decLang_Selected = monthNameIndonesia;
                break;
            case "kr":
                decLang_Selected = monthNameKorea;
                break;
            case "th":
                decLang_Selected = monthNameThailand;
                break;
        }

        setTimeout(() => {
            // Ganti warna jadi putih pada label 'Year' di atas (khusus month grid)
            const yearLabelTop: any = document.querySelectorAll(".bs-datepicker-head button.current span");
            yearLabelTop.forEach((ele, idx) => {
                (<HTMLElement>ele).classList.add("custom-label-monthyear");
            });
            // ... end

            const monthGrid: any = document.querySelectorAll(".bs-datepicker-body table.months td span");
            monthGrid.forEach((ele, idx) => {
                (<HTMLElement>ele).classList.add("custom-label-monthyear");

                const monthLabel: any = (<HTMLElement>ele).innerText;
                // kondisi default datepicker adalah english, jadi akan digantikan dengan bahasa yang sesuai
                let findIdx = monthName.findIndex((res) => res == monthLabel);
                if (findIdx != -1) {
                    (<HTMLElement>ele).innerText = decLang_Selected[findIdx];
                }
            });
        });

        //  INTERVAL TUNGGU DAY SETELAH CLICK SATU MONTH (TIMPA KEMBALI NAMA BULAN YG TIDAK SESUAI DENGAN LANGUAGE YG DIPILIH)
        const intervalDayGrid = setInterval(() => {
            const dayGrid = document.querySelectorAll(".bs-datepicker-body table.days td ");
            if (dayGrid.length > 0) {
                // looping semua periode label di atas (e.g. January 2022) pada saat table 'month' ke 'day'
                const labelMonth = document.querySelectorAll(".bs-datepicker-head button.current span");
                labelMonth.forEach((ele, idx) => {
                    const loopMonth = (<HTMLElement>ele).innerText;

                    let findIdx = decLang_Selected.findIndex((res) => res == loopMonth.toString());
                    // jika tidak ketemu dari versi language terpilih, maka cari yang versi default (english)
                    if (findIdx == -1) {
                        // jika tidak ketemu, maka di cari di versi default (english)
                        let findIdxDefaultLang = monthName.findIndex((res) => res == loopMonth.toString());

                        // mencegah 'Year' yang ter-compare dengan array yang seharusnya tidak match, maka di skip
                        if (findIdxDefaultLang != -1) {
                            (<HTMLElement>document.querySelectorAll(".bs-datepicker-head button.current")[idx]).innerText = decLang_Selected[findIdxDefaultLang];
                        }
                    }
                });
                // ... end

                // (DAY) UPDATE KEMBALI DAY LABEL SETELAH DARI MONTH GRID
                let daySunConvert: any;
                let dayMonConvert: any;
                let dayTueConvert: any;
                let dayWedConvert: any;
                let dayThuConvert: any;
                let dayFriConvert: any;
                let daySatConvert: any;
                switch (decLang) {
                    case "id":
                        daySunConvert = "M";
                        dayMonConvert = "Sn";
                        dayTueConvert = "Se";
                        dayWedConvert = "R";
                        dayThuConvert = "K";
                        dayFriConvert = "J";
                        daySatConvert = "Sb";
                        break;
                    case "kr":
                        daySunConvert = "일";
                        dayMonConvert = "월";
                        dayTueConvert = "화";
                        dayWedConvert = "수";
                        dayThuConvert = "목";
                        dayFriConvert = "금";
                        daySatConvert = "토";
                        break;
                    case "th":
                        daySunConvert = "อาทิตย์";
                        dayMonConvert = "จันทร์";
                        dayTueConvert = "อังคาร";
                        dayWedConvert = "พุธ";
                        dayThuConvert = "พฤหัสบดี";
                        dayFriConvert = "ศุกร์";
                        daySatConvert = "เสาร์";
                        break;
                    case "en":
                    default:
                        daySunConvert = "Sun";
                        dayMonConvert = "Mon";
                        dayTueConvert = "Tue";
                        dayWedConvert = "Wed";
                        dayThuConvert = "Thu";
                        dayFriConvert = "Fri";
                        daySatConvert = "Sat";
                        break;
                }
                const daysWeek = document.querySelectorAll(".bs-datepicker-body table.days.weeks thead tr th");
                daysWeek.forEach((ele, idx) => {
                    // #9aaec1 warna default
                    (<HTMLElement>ele).classList.add("custom-label-day");

                    switch (idx) {
                        case 1:
                            (<HTMLElement>ele).innerText = daySunConvert;
                            break; //sunday
                        case 2:
                            (<HTMLElement>ele).innerText = dayMonConvert;
                            break; //monday
                        case 3:
                            (<HTMLElement>ele).innerText = dayTueConvert;
                            break;
                        case 4:
                            (<HTMLElement>ele).innerText = dayWedConvert;
                            break;
                        case 5:
                            (<HTMLElement>ele).innerText = dayThuConvert;
                            break;
                        case 6:
                            (<HTMLElement>ele).innerText = dayFriConvert;
                            break;
                        case 7:
                            (<HTMLElement>ele).innerText = daySatConvert;
                            break;
                    }
                });
                // ... end

                clearInterval(intervalDayGrid);
            }
        });
    }

    funcSwalGlobal(
        tipe:
            | "submit-cor-upd"
            | "change-aod"
            | "delete-cor-upd"
            | "del-additional-balance"
            | "employee-performance"
            | "employee-assignment"
            | "employee-perf-rating"
            | "reset-password"
            | "change-emp-assign-data"
            | "confirm-process-dialog"
            | "recalculated-request"
            | "reprocessed-request"
            | "run-process-confirm"
            | "employee-career-assessment"
            | "new-update"
            | "reprocessed-fail-instance"
            | "cancel-request"
            | "rollback-request"
            | "reset-manage-evaluation"
            | "submit-career-evaluation",
        callback?
    ) {
        switch (tipe) {
            case "change-aod":
                swal.fire({
                    title: this.langChangeList["changeAod"][this.langArrNum]["title"],
                    text: this.langChangeList["changeAod"][this.langArrNum]["text"],
                    icon: "warning",
                    showCloseButton: true,
                    showCancelButton: true,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-danger",
                    },
                    confirmButtonText: this.langChangeList["changeAod"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["changeAod"][this.langArrNum]["cancelBtn"], //ADD NEW DATA EFFECTIVE DATE
                }).then((result) => {
                    callback(result);
                });
                break;

            case "submit-cor-upd":
                swal.fire({
                    title: this.langChangeList["swal"][this.langArrNum]["chooseAnOption"],
                    icon: "question",
                    showCancelButton: true,
                    footer:
                        `
                  <div class = 'text-left' style = 'font-size:12px'>
                      <span class = 'text-primary'><b>` +
                        this.langChangeList["swal"][this.langArrNum]["footerTitle1"] +
                        `</b></span><br>
                      <span><b>` +
                        this.langChangeList["swal"][this.langArrNum]["footerTitle2"] +
                        `</b></span>
                  </div>
              `,
                    showCloseButton: true,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-dark",
                    },
                    confirmButtonText: this.langChangeList["swal"][this.langArrNum]["correction"],
                    cancelButtonText: this.langChangeList["swal"][this.langArrNum]["update"], //ADD NEW DATA EFFECTIVE DATE\
                }).then((result) => {
                    callback(result);
                });
                break;
            case "delete-cor-upd":
                swal.fire({
                    title: this.langChangeList["deleteForm"][this.langArrNum]["title"],
                    text: this.langChangeList["deleteForm"][this.langArrNum]["text"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-dark",
                    },
                    confirmButtonText: this.langChangeList["deleteForm"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["deleteForm"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "reset-manage-evaluation":
                swal.fire({
                    title: this.langChangeList["resetManageEvaluation"][this.langArrNum]["title"],
                    text: this.langChangeList["resetManageEvaluation"][this.langArrNum]["text"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-dark",
                    },
                    confirmButtonText: this.langChangeList["resetManageEvaluation"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["resetManageEvaluation"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "del-additional-balance":
                swal.fire({
                    title: this.langChangeList["delAdditionalBalance"][this.langArrNum]["title"],
                    text: this.langChangeList["delAdditionalBalance"][this.langArrNum]["text"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-dark",
                    },
                    confirmButtonText: this.langChangeList["delAdditionalBalance"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["delAdditionalBalance"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "employee-performance":
                swal.fire({
                    title: this.langChangeList["swalEmployeePerformance"][this.langArrNum]["title"],
                    text: this.langChangeList["swalEmployeePerformance"][this.langArrNum]["textData"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-danger",
                    },
                    confirmButtonText: this.langChangeList["swalEmployeePerformance"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["swalEmployeePerformance"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "employee-career-assessment":
                swal.fire({
                    title: this.langChangeList["swalEmployeeCareerAssessment"][this.langArrNum]["title"],
                    text: this.langChangeList["swalEmployeeCareerAssessment"][this.langArrNum]["textData"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-danger",
                    },
                    confirmButtonText: this.langChangeList["swalEmployeeCareerAssessment"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["swalEmployeeCareerAssessment"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "employee-perf-rating":
                swal.fire({
                    title: this.langChangeList["swalEmployeePerformance"][this.langArrNum]["title"],
                    text: this.langChangeList["swalEmployeePerformance"][this.langArrNum]["textRating"],
                    icon: "question",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-danger",
                    },
                    confirmButtonText: this.langChangeList["swalEmployeePerformance"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["swalEmployeePerformance"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "employee-assignment":
                swal.fire({
                    title: this.langChangeList["swalEmployeeAssignment"][this.langArrNum]["title"],
                    text: this.langChangeList["swalEmployeeAssignment"][this.langArrNum]["text"],
                    icon: "question",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-danger",
                    },
                    confirmButtonText: this.langChangeList["swalEmployeeAssignment"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["swalEmployeeAssignment"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "reset-password":
                swal.fire({
                    title: this.langChangeList["resetPassword"][this.langArrNum]["title"],
                    text: this.langChangeList["resetPassword"][this.langArrNum]["text"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-danger",
                    },
                    confirmButtonText: this.langChangeList["resetPassword"][this.langArrNum]["resetBtn"],
                    cancelButtonText: this.langChangeList["resetPassword"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "change-emp-assign-data":
                swal.fire({
                    title: this.langChangeList["swalEmployeeAssignment"][this.langArrNum]["title"],
                    text: this.langChangeList["swalEmployeeAssignment"][this.langArrNum]["text"],
                    icon: "question",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-dark",
                    },
                    confirmButtonText: this.langChangeList["swalEmployeeAssignment"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["swalEmployeeAssignment"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "confirm-process-dialog":
                swal.fire({
                    title: this.langChangeList["confirmProcessDialog"][this.langArrNum]["title"],
                    text: this.langChangeList["confirmProcessDialog"][this.langArrNum]["text"],
                    icon: "warning",
                    showCloseButton: true,
                    showCancelButton: true,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-danger",
                    },
                    confirmButtonText: this.langChangeList["confirmProcessDialog"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["confirmProcessDialog"][this.langArrNum]["cancelBtn"], //ADD NEW DATA EFFECTIVE DATE
                }).then((result) => {
                    callback(result);
                });
                break;
            case "recalculated-request":
                swal.fire({
                    title: this.langChangeList["recalculateRequests"][this.langArrNum]["title"],
                    text: this.langChangeList["recalculateRequests"][this.langArrNum]["text"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-dark",
                    },
                    confirmButtonText: this.langChangeList["recalculateRequests"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["recalculateRequests"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "reprocessed-fail-instance":
                swal.fire({
                    title: this.langChangeList["recalculateRequests"][this.langArrNum]["title"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-dark",
                    },
                    confirmButtonText: this.langChangeList["recalculateRequests"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["recalculateRequests"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "reprocessed-request":
                swal.fire({
                    title: this.langChangeList["reprocessedRequests"][this.langArrNum]["title"],
                    text: this.langChangeList["reprocessedRequests"][this.langArrNum]["text"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-dark",
                    },
                    confirmButtonText: this.langChangeList["reprocessedRequests"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["reprocessedRequests"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "cancel-request":
                swal.fire({
                    title: this.langChangeList["cancelRequests"][this.langArrNum]["title"],
                    text: this.langChangeList["cancelRequests"][this.langArrNum]["text"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-dark",
                    },
                    confirmButtonText: this.langChangeList["cancelRequests"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["cancelRequests"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "rollback-request":
                swal.fire({
                    title: this.langChangeList["rollbackRequests"][this.langArrNum]["title"],
                    text: this.langChangeList["rollbackRequests"][this.langArrNum]["text"],
                    icon: "warning",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-dark",
                    },
                    confirmButtonText: this.langChangeList["rollbackRequests"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["rollbackRequests"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "submit-career-evaluation":
                swal.fire({
                    title: this.langChangeList["submitCareerEval"][this.langArrNum]["title"],
                    text: this.langChangeList["submitCareerEval"][this.langArrNum]["text"],
                    icon: "question",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    // Switch between confirmBtn and cancelBtn
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-primary",
                    },
                    confirmButtonText: this.langChangeList["submitCareerEval"][this.langArrNum]["cancelBtn"],
                    cancelButtonText: this.langChangeList["submitCareerEval"][this.langArrNum]["confirmBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
            case "run-process-confirm":
                swal.fire({
                    title: this.langChangeList["runProcessConfirm"][this.langArrNum]["desc"],
                    // text:this.langChangeList['submitCareerEval'][this.langArrNum]['text'],
                    icon: "question",
                    showCancelButton: true,
                    showCloseButton: false,
                    buttonsStyling: false,
                    // Switch between confirmBtn and cancelBtn
                    customClass: {
                        confirmButton: "btn btn-success",
                        cancelButton: "btn btn-danger",
                    },
                    confirmButtonText: this.langChangeList["runProcessConfirm"][this.langArrNum]["confirmBtn"],
                    cancelButtonText: this.langChangeList["runProcessConfirm"][this.langArrNum]["cancelBtn"],
                }).then((result) => {
                    callback(result);
                });
                break;
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }
}
